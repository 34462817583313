const CodePays = [
  {
    "COG": "99101",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "DANEMARK",
    "LIBENR": "ROYAUME DU DANEMARK",
    "ANCNOM": "",
    "CODEISO2": "DK",
    "CODEISO3": "DNK",
    "CODENUM3": "208"
  },
  {
    "COG": "99101",
    "ACTUAL": "3",
    "CAPAY": "99102",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "FEROE (ILES)",
    "LIBENR": "FÉROÉ (ÎLES)",
    "ANCNOM": "",
    "CODEISO2": "FO",
    "CODEISO3": "FRO",
    "CODENUM3": "234"
  },
  {
    "COG": "99102",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1944",
    "LIBCOG": "ISLANDE",
    "LIBENR": "RÉPUBLIQUE D'ISLANDE",
    "ANCNOM": "",
    "CODEISO2": "IS",
    "CODEISO3": "ISL",
    "CODENUM3": "352"
  },
  {
    "COG": "99103",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "NORVEGE",
    "LIBENR": "ROYAUME DE NORVÈGE",
    "ANCNOM": "",
    "CODEISO2": "NO",
    "CODEISO3": "NOR",
    "CODENUM3": "578"
  },
  {
    "COG": "99103",
    "ACTUAL": "3",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "BOUVET (ILE)",
    "LIBENR": "BOUVET (ÎLE)",
    "ANCNOM": "",
    "CODEISO2": "BV",
    "CODEISO3": "BVT",
    "CODENUM3": "074"
  },
  {
    "COG": "99103",
    "ACTUAL": "3",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "SVALBARD et ILE JAN MAYEN",
    "LIBENR": "SVALBARD ET ÎLE JAN MAYEN",
    "ANCNOM": "",
    "CODEISO2": "SJ",
    "CODEISO3": "SJM",
    "CODENUM3": "744"
  },
  {
    "COG": "99104",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "SUEDE",
    "LIBENR": "ROYAUME DE SUÈDE",
    "ANCNOM": "",
    "CODEISO2": "SE",
    "CODEISO3": "SWE",
    "CODENUM3": "752"
  },
  {
    "COG": "99105",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "FINLANDE",
    "LIBENR": "RÉPUBLIQUE DE FINLANDE",
    "ANCNOM": "",
    "CODEISO2": "FI",
    "CODEISO3": "FIN",
    "CODENUM3": "246"
  },
  {
    "COG": "99106",
    "ACTUAL": "1",
    "CAPAY": "99123",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "ESTONIE",
    "LIBENR": "RÉPUBLIQUE D'ESTONIE",
    "ANCNOM": "",
    "CODEISO2": "EE",
    "CODEISO3": "EST",
    "CODENUM3": "233"
  },
  {
    "COG": "99107",
    "ACTUAL": "1",
    "CAPAY": "99123",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "LETTONIE",
    "LIBENR": "RÉPUBLIQUE DE LETTONIE",
    "ANCNOM": "",
    "CODEISO2": "LV",
    "CODEISO3": "LVA",
    "CODENUM3": "428"
  },
  {
    "COG": "99108",
    "ACTUAL": "1",
    "CAPAY": "99123",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "LITUANIE",
    "LIBENR": "RÉPUBLIQUE DE LITUANIE",
    "ANCNOM": "",
    "CODEISO2": "LT",
    "CODEISO3": "LTU",
    "CODENUM3": "440"
  },
  {
    "COG": "99109",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1990",
    "LIBCOG": "ALLEMAGNE",
    "LIBENR": "RÉPUBLIQUE FÉDÉRALE D'ALLEMAGNE",
    "ANCNOM": "",
    "CODEISO2": "DE",
    "CODEISO3": "DEU",
    "CODENUM3": "276"
  },
  {
    "COG": "99110",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "AUTRICHE",
    "LIBENR": "RÉPUBLIQUE D'AUTRICHE",
    "ANCNOM": "",
    "CODEISO2": "AT",
    "CODEISO3": "AUT",
    "CODENUM3": "040"
  },
  {
    "COG": "99111",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "BULGARIE",
    "LIBENR": "RÉPUBLIQUE DE BULGARIE",
    "ANCNOM": "",
    "CODEISO2": "BG",
    "CODEISO3": "BGR",
    "CODENUM3": "100"
  },
  {
    "COG": "99112",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "HONGRIE",
    "LIBENR": "RÉPUBLIQUE DE HONGRIE",
    "ANCNOM": "",
    "CODEISO2": "HU",
    "CODEISO3": "HUN",
    "CODENUM3": "348"
  },
  {
    "COG": "99113",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "LIECHTENSTEIN",
    "LIBENR": "PRINCIPAUTÉ DE LIECHTENSTEIN",
    "ANCNOM": "",
    "CODEISO2": "LI",
    "CODEISO3": "LIE",
    "CODENUM3": "438"
  },
  {
    "COG": "99114",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "ROUMANIE",
    "LIBENR": "ROUMANIE",
    "ANCNOM": "",
    "CODEISO2": "RO",
    "CODEISO3": "ROU",
    "CODENUM3": "642"
  },
  {
    "COG": "99115",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "TCHECOSLOVAQUIE",
    "LIBENR": "TCHÉCOSLOVAQUIE",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99116",
    "ACTUAL": "1",
    "CAPAY": "99115",
    "CRPAY": "",
    "ANI": "1992",
    "LIBCOG": "TCHEQUIE",
    "LIBENR": "RÉPUBLIQUE TCHÈQUE",
    "ANCNOM": "",
    "CODEISO2": "CZ",
    "CODEISO3": "CZE",
    "CODENUM3": "203"
  },
  {
    "COG": "99117",
    "ACTUAL": "1",
    "CAPAY": "99115",
    "CRPAY": "",
    "ANI": "1992",
    "LIBCOG": "SLOVAQUIE",
    "LIBENR": "RÉPUBLIQUE SLOVAQUE",
    "ANCNOM": "",
    "CODEISO2": "SK",
    "CODEISO3": "SVK",
    "CODENUM3": "703"
  },
  {
    "COG": "99118",
    "ACTUAL": "1",
    "CAPAY": "99121",
    "CRPAY": "",
    "ANI": "1992",
    "LIBCOG": "BOSNIE-HERZEGOVINE",
    "LIBENR": "RÉPUBLIQUE DE BOSNIE-HERZÉGOVINE",
    "ANCNOM": "",
    "CODEISO2": "BA",
    "CODEISO3": "BIH",
    "CODENUM3": "070"
  },
  {
    "COG": "99119",
    "ACTUAL": "1",
    "CAPAY": "99121",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "CROATIE",
    "LIBENR": "RÉPUBLIQUE DE CROATIE",
    "ANCNOM": "",
    "CODEISO2": "HR",
    "CODEISO3": "HRV",
    "CODENUM3": "191"
  },
  {
    "COG": "99120",
    "ACTUAL": "1",
    "CAPAY": "99121",
    "CRPAY": "",
    "ANI": "2006",
    "LIBCOG": "MONTENEGRO",
    "LIBENR": "RÉPUBLIQUE DU MONTÉNÉGRO",
    "ANCNOM": "SERBIE-ET-MONTENEGRO",
    "CODEISO2": "ME",
    "CODEISO3": "MNE",
    "CODENUM3": "499"
  },
  {
    "COG": "99121",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "2006",
    "LIBCOG": "SERBIE",
    "LIBENR": "RÉPUBLIQUE DE SERBIE",
    "ANCNOM": "SERBIE-ET-MONTENEGRO",
    "CODEISO2": "RS",
    "CODEISO3": "SRB",
    "CODENUM3": "688"
  },
  {
    "COG": "99122",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "POLOGNE",
    "LIBENR": "RÉPUBLIQUE DE POLOGNE",
    "ANCNOM": "",
    "CODEISO2": "PL",
    "CODEISO3": "POL",
    "CODENUM3": "616"
  },
  {
    "COG": "99123",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "RUSSIE",
    "LIBENR": "FÉDÉRATION DE RUSSIE",
    "ANCNOM": "U.R.S.S.",
    "CODEISO2": "RU",
    "CODEISO3": "RUS",
    "CODENUM3": "643"
  },
  {
    "COG": "99124",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "99208",
    "ANI": "",
    "LIBCOG": "TURQUIE D'EUROPE",
    "LIBENR": "TURQUIE D'EUROPE",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99125",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "ALBANIE",
    "LIBENR": "RÉPUBLIQUE D'ALBANIE",
    "ANCNOM": "",
    "CODEISO2": "AL",
    "CODEISO3": "ALB",
    "CODENUM3": "008"
  },
  {
    "COG": "99126",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "GRECE",
    "LIBENR": "RÉPUBLIQUE HELLÉNIQUE",
    "ANCNOM": "",
    "CODEISO2": "GR",
    "CODEISO3": "GRC",
    "CODENUM3": "300"
  },
  {
    "COG": "99127",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "ITALIE",
    "LIBENR": "RÉPUBLIQUE ITALIENNE",
    "ANCNOM": "",
    "CODEISO2": "IT",
    "CODEISO3": "ITA",
    "CODENUM3": "380"
  },
  {
    "COG": "99128",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "SAINT-MARIN",
    "LIBENR": "RÉPUBLIQUE DE SAINT-MARIN",
    "ANCNOM": "",
    "CODEISO2": "SM",
    "CODEISO3": "SMR",
    "CODENUM3": "674"
  },
  {
    "COG": "99129",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "VATICAN, ou SAINT-SIEGE",
    "LIBENR": "ÉTAT DE LA CITÉ DU VATICAN",
    "ANCNOM": "",
    "CODEISO2": "VA",
    "CODEISO3": "VAT",
    "CODENUM3": "336"
  },
  {
    "COG": "99130",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "ANDORRE",
    "LIBENR": "PRINCIPAUTÉ D'ANDORRE",
    "ANCNOM": "",
    "CODEISO2": "AD",
    "CODEISO3": "AND",
    "CODENUM3": "020"
  },
  {
    "COG": "99131",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "BELGIQUE",
    "LIBENR": "ROYAUME DE BELGIQUE",
    "ANCNOM": "",
    "CODEISO2": "BE",
    "CODEISO3": "BEL",
    "CODENUM3": "056"
  },
  {
    "COG": "99132",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "ROYAUME-UNI",
    "LIBENR": "ROYAUME-UNI DE GRANDE-BRETAGNE ET D'IRLANDE DU NORD",
    "ANCNOM": "",
    "CODEISO2": "GB",
    "CODEISO3": "GBR",
    "CODENUM3": "826"
  },
  {
    "COG": "99132",
    "ACTUAL": "3",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "GUERNESEY",
    "LIBENR": "GUERNESEY",
    "ANCNOM": "",
    "CODEISO2": "GG",
    "CODEISO3": "GGY",
    "CODENUM3": "831"
  },
  {
    "COG": "99132",
    "ACTUAL": "3",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "MAN (ILE)",
    "LIBENR": "MAN (ÎLE)",
    "ANCNOM": "",
    "CODEISO2": "IM",
    "CODEISO3": "IMN",
    "CODENUM3": "833"
  },
  {
    "COG": "99132",
    "ACTUAL": "3",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "JERSEY",
    "LIBENR": "JERSEY",
    "ANCNOM": "",
    "CODEISO2": "JE",
    "CODEISO3": "JEY",
    "CODENUM3": "832"
  },
  {
    "COG": "99133",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99132",
    "ANI": "",
    "LIBCOG": "GIBRALTAR",
    "LIBENR": "GIBRALTAR",
    "ANCNOM": "",
    "CODEISO2": "GI",
    "CODEISO3": "GIB",
    "CODENUM3": "292"
  },
  {
    "COG": "99134",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "ESPAGNE",
    "LIBENR": "ROYAUME D'ESPAGNE",
    "ANCNOM": "",
    "CODEISO2": "ES",
    "CODEISO3": "ESP",
    "CODENUM3": "724"
  },
  {
    "COG": "99135",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "PAYS-BAS",
    "LIBENR": "ROYAUME DES PAYS-BAS",
    "ANCNOM": "HOLLANDE",
    "CODEISO2": "NL",
    "CODEISO3": "NLD",
    "CODENUM3": "528"
  },
  {
    "COG": "99135",
    "ACTUAL": "3",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "ARUBA",
    "LIBENR": "ARUBA",
    "ANCNOM": "",
    "CODEISO2": "AW",
    "CODEISO3": "ABW",
    "CODENUM3": "533"
  },
  {
    "COG": "99136",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "IRLANDE, ou EIRE",
    "LIBENR": "IRLANDE",
    "ANCNOM": "",
    "CODEISO2": "IE",
    "CODEISO3": "IRL",
    "CODENUM3": "372"
  },
  {
    "COG": "99137",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "LUXEMBOURG",
    "LIBENR": "GRAND-DUCHÉ DE LUXEMBOURG",
    "ANCNOM": "",
    "CODEISO2": "LU",
    "CODEISO3": "LUX",
    "CODENUM3": "442"
  },
  {
    "COG": "99138",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "MONACO",
    "LIBENR": "PRINCIPAUTÉ DE MONACO",
    "ANCNOM": "",
    "CODEISO2": "MC",
    "CODEISO3": "MCO",
    "CODENUM3": "492"
  },
  {
    "COG": "99139",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "PORTUGAL",
    "LIBENR": "RÉPUBLIQUE PORTUGAISE",
    "ANCNOM": "",
    "CODEISO2": "PT",
    "CODEISO3": "PRT",
    "CODENUM3": "620"
  },
  {
    "COG": "99140",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "SUISSE",
    "LIBENR": "CONFÉDÉRATION SUISSE",
    "ANCNOM": "",
    "CODEISO2": "CH",
    "CODEISO3": "CHE",
    "CODENUM3": "756"
  },
  {
    "COG": "99141",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "99109",
    "ANI": "",
    "LIBCOG": "REPUBLIQUE DEMOCRATIQUE ALLEMANDE",
    "LIBENR": "RÉPUBLIQUE DÉMOCRATIQUE ALLEMANDE",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99142",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "99109",
    "ANI": "",
    "LIBCOG": "REPUBLIQUE FEDERALE D'ALLEMAGNE",
    "LIBENR": "RÉPUBLIQUE FÉDÉRALE D'ALLEMAGNE",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99144",
    "ACTUAL": "1",
    "CAPAY": "99133",
    "CRPAY": "",
    "ANI": "1964",
    "LIBCOG": "MALTE",
    "LIBENR": "RÉPUBLIQUE DE MALTE",
    "ANCNOM": "",
    "CODEISO2": "MT",
    "CODEISO3": "MLT",
    "CODENUM3": "470"
  },
  {
    "COG": "99145",
    "ACTUAL": "1",
    "CAPAY": "99121",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "SLOVENIE",
    "LIBENR": "RÉPUBLIQUE DE SLOVÉNIE",
    "ANCNOM": "",
    "CODEISO2": "SI",
    "CODEISO3": "SVN",
    "CODENUM3": "705"
  },
  {
    "COG": "99148",
    "ACTUAL": "1",
    "CAPAY": "99123",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "BIELORUSSIE",
    "LIBENR": "RÉPUBLIQUE DE BIÉLORUSSIE",
    "ANCNOM": "",
    "CODEISO2": "BY",
    "CODEISO3": "BLR",
    "CODENUM3": "112"
  },
  {
    "COG": "99151",
    "ACTUAL": "1",
    "CAPAY": "99123",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "MOLDAVIE",
    "LIBENR": "RÉPUBLIQUE DE MOLDAVIE",
    "ANCNOM": "",
    "CODEISO2": "MD",
    "CODEISO3": "MDA",
    "CODENUM3": "498"
  },
  {
    "COG": "99155",
    "ACTUAL": "1",
    "CAPAY": "99123",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "UKRAINE",
    "LIBENR": "UKRAINE",
    "ANCNOM": "",
    "CODEISO2": "UA",
    "CODEISO3": "UKR",
    "CODENUM3": "804"
  },
  {
    "COG": "99156",
    "ACTUAL": "1",
    "CAPAY": "99121",
    "CRPAY": "",
    "ANI": "1993",
    "LIBCOG": "MACEDOINE DU NORD",
    "LIBENR": "RÉPUBLIQUE DE MACÉDOINE DU NORD",
    "ANCNOM": "EX-REPUBLIQUE YOUGOSLAVE DE MACEDOINE",
    "CODEISO2": "MK",
    "CODEISO3": "MKD",
    "CODENUM3": "807"
  },
  {
    "COG": "99157",
    "ACTUAL": "1",
    "CAPAY": "99121",
    "CRPAY": "",
    "ANI": "2008",
    "LIBCOG": "KOSOVO",
    "LIBENR": "RÉPUBLIQUE DU KOSOVO",
    "ANCNOM": "SERBIE",
    "CODEISO2": "XK",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99201",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "ARABIE SAOUDITE",
    "LIBENR": "ROYAUME D'ARABIE SAOUDITE",
    "ANCNOM": "",
    "CODEISO2": "SA",
    "CODEISO3": "SAU",
    "CODENUM3": "682"
  },
  {
    "COG": "99202",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "99251",
    "ANI": "",
    "LIBCOG": "YEMEN (REPUBLIQUE ARABE DU)",
    "LIBENR": "YÉMEN (RÉPUBLIQUE ARABE DU)",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99203",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "IRAQ",
    "LIBENR": "RÉPUBLIQUE D'IRAQ",
    "ANCNOM": "",
    "CODEISO2": "IQ",
    "CODEISO3": "IRQ",
    "CODENUM3": "368"
  },
  {
    "COG": "99204",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "IRAN",
    "LIBENR": "RÉPUBLIQUE ISLAMIQUE D'IRAN",
    "ANCNOM": "PERSE",
    "CODEISO2": "IR",
    "CODEISO3": "IRN",
    "CODENUM3": "364"
  },
  {
    "COG": "99205",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1943",
    "LIBCOG": "LIBAN",
    "LIBENR": "RÉPUBLIQUE LIBANAISE",
    "ANCNOM": "",
    "CODEISO2": "LB",
    "CODEISO3": "LBN",
    "CODENUM3": "422"
  },
  {
    "COG": "99206",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1944",
    "LIBCOG": "SYRIE",
    "LIBENR": "RÉPUBLIQUE ARABE SYRIENNE",
    "ANCNOM": "",
    "CODEISO2": "SY",
    "CODEISO3": "SYR",
    "CODENUM3": "760"
  },
  {
    "COG": "99207",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1948",
    "LIBCOG": "ISRAEL",
    "LIBENR": "ÉTAT D'ISRAËL",
    "ANCNOM": "",
    "CODEISO2": "IL",
    "CODEISO3": "ISR",
    "CODENUM3": "376"
  },
  {
    "COG": "99208",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "TURQUIE",
    "LIBENR": "RÉPUBLIQUE TURQUE",
    "ANCNOM": "",
    "CODEISO2": "TR",
    "CODEISO3": "TUR",
    "CODENUM3": "792"
  },
  {
    "COG": "99209",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "99123",
    "ANI": "",
    "LIBCOG": "SIBERIE",
    "LIBENR": "SIBÉRIE",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99210",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "99123",
    "ANI": "",
    "LIBCOG": "TURKESTAN RUSSE",
    "LIBENR": "TURKESTAN RUSSE",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99211",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "99123",
    "ANI": "",
    "LIBCOG": "KAMTCHATKA",
    "LIBENR": "KAMTCHATKA",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99212",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "AFGHANISTAN",
    "LIBENR": "ÉTAT ISLAMIQUE D'AFGHANISTAN",
    "ANCNOM": "",
    "CODEISO2": "AF",
    "CODEISO3": "AFG",
    "CODENUM3": "004"
  },
  {
    "COG": "99213",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1947",
    "LIBCOG": "PAKISTAN",
    "LIBENR": "RÉPUBLIQUE ISLAMIQUE DU PAKISTAN",
    "ANCNOM": "",
    "CODEISO2": "PK",
    "CODEISO3": "PAK",
    "CODENUM3": "586"
  },
  {
    "COG": "99214",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "BHOUTAN",
    "LIBENR": "ROYAUME DU BHOUTAN",
    "ANCNOM": "",
    "CODEISO2": "BT",
    "CODEISO3": "BTN",
    "CODENUM3": "064"
  },
  {
    "COG": "99215",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "NEPAL",
    "LIBENR": "NÉPAL",
    "ANCNOM": "",
    "CODEISO2": "NP",
    "CODEISO3": "NPL",
    "CODENUM3": "524"
  },
  {
    "COG": "99216",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "CHINE",
    "LIBENR": "RÉPUBLIQUE POPULAIRE DE CHINE",
    "ANCNOM": "",
    "CODEISO2": "CN",
    "CODEISO3": "CHN",
    "CODENUM3": "156"
  },
  {
    "COG": "99217",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "JAPON",
    "LIBENR": "JAPON",
    "ANCNOM": "",
    "CODEISO2": "JP",
    "CODEISO3": "JPN",
    "CODENUM3": "392"
  },
  {
    "COG": "99218",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "99216",
    "ANI": "",
    "LIBCOG": "MANDCHOURIE",
    "LIBENR": "MANDCHOURIE",
    "ANCNOM": "MANDCHOUKOUO",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99219",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "THAILANDE",
    "LIBENR": "ROYAUME DE THAÏLANDE",
    "ANCNOM": "SIAM",
    "CODEISO2": "TH",
    "CODEISO3": "THA",
    "CODENUM3": "764"
  },
  {
    "COG": "99220",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1946",
    "LIBCOG": "PHILIPPINES",
    "LIBENR": "RÉPUBLIQUE DES PHILIPPINES",
    "ANCNOM": "",
    "CODEISO2": "PH",
    "CODEISO3": "PHL",
    "CODENUM3": "608"
  },
  {
    "COG": "99221",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "POSSESSIONS BRITANNIQUES AU PROCHE-ORIENT",
    "LIBENR": "POSSESSIONS BRITANNIQUES AU PROCHE-ORIENT",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99222",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1946",
    "LIBCOG": "JORDANIE",
    "LIBENR": "ROYAUME HACHÉMITE DE JORDANIE",
    "ANCNOM": "TRANSJORDANIE",
    "CODEISO2": "JO",
    "CODEISO3": "JOR",
    "CODENUM3": "400"
  },
  {
    "COG": "99223",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1947",
    "LIBCOG": "INDE",
    "LIBENR": "RÉPUBLIQUE DE L'INDE",
    "ANCNOM": "",
    "CODEISO2": "IN",
    "CODEISO3": "IND",
    "CODENUM3": "356"
  },
  {
    "COG": "99223",
    "ACTUAL": "3",
    "CAPAY": "99232",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "GOA",
    "LIBENR": "GOA",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99224",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1948",
    "LIBCOG": "BIRMANIE",
    "LIBENR": "UNION DE BIRMANIE",
    "ANCNOM": "",
    "CODEISO2": "MM",
    "CODEISO3": "MMR",
    "CODENUM3": "104"
  },
  {
    "COG": "99225",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1984",
    "LIBCOG": "BRUNEI",
    "LIBENR": "NEGARA BRUNEI DARUSSALAM",
    "ANCNOM": "BORNEO BRITANNIQUE",
    "CODEISO2": "BN",
    "CODEISO3": "BRN",
    "CODENUM3": "096"
  },
  {
    "COG": "99226",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1965",
    "LIBCOG": "SINGAPOUR",
    "LIBENR": "RÉPUBLIQUE DE SINGAPOUR",
    "ANCNOM": "ETABLS. DES DETROITS",
    "CODEISO2": "SG",
    "CODEISO3": "SGP",
    "CODENUM3": "702"
  },
  {
    "COG": "99227",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1957",
    "LIBCOG": "MALAISIE",
    "LIBENR": "MALAISIE",
    "ANCNOM": "",
    "CODEISO2": "MY",
    "CODEISO3": "MYS",
    "CODENUM3": "458"
  },
  {
    "COG": "99228",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "99227",
    "ANI": "",
    "LIBCOG": "ETATS MALAIS NON FEDERES",
    "LIBENR": "ÉTATS MALAIS NON FÉDÉRÉS",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99229",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1965",
    "LIBCOG": "MALDIVES",
    "LIBENR": "RÉPUBLIQUE DES MALDIVES",
    "ANCNOM": "ILES MALDIVES",
    "CODEISO2": "MV",
    "CODEISO3": "MDV",
    "CODENUM3": "462"
  },
  {
    "COG": "99230",
    "ACTUAL": "2",
    "CAPAY": "99132",
    "CRPAY": "99216",
    "ANI": "",
    "LIBCOG": "HONG-KONG",
    "LIBENR": "HONG-KONG",
    "ANCNOM": "",
    "CODEISO2": "HK",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99231",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1945",
    "LIBCOG": "INDONESIE",
    "LIBENR": "RÉPUBLIQUE D'INDONÉSIE",
    "ANCNOM": "INDES NEER. ET PORT.",
    "CODEISO2": "ID",
    "CODEISO3": "IDN",
    "CODENUM3": "360"
  },
  {
    "COG": "99232",
    "ACTUAL": "2",
    "CAPAY": "99139",
    "CRPAY": "99216",
    "ANI": "",
    "LIBCOG": "MACAO",
    "LIBENR": "MACAO",
    "ANCNOM": "",
    "CODEISO2": "MO",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99233",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "99251",
    "ANI": "",
    "LIBCOG": "YEMEN DEMOCRATIQUE",
    "LIBENR": "YÉMEN DÉMOCRATIQUE",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99234",
    "ACTUAL": "1",
    "CAPAY": "98503",
    "CRPAY": "",
    "ANI": "1953",
    "LIBCOG": "CAMBODGE",
    "LIBENR": "ROYAUME DU CAMBODGE",
    "ANCNOM": "KAMPUCHEA DEM.",
    "CODEISO2": "KH",
    "CODEISO3": "KHM",
    "CODENUM3": "116"
  },
  {
    "COG": "99235",
    "ACTUAL": "1",
    "CAPAY": "99223",
    "CRPAY": "",
    "ANI": "1948",
    "LIBCOG": "SRI LANKA",
    "LIBENR": "RÉPUBLIQUE DÉMOCRATIQUE SOCIALISTE DU SRI LANKA",
    "ANCNOM": "CEYLAN",
    "CODEISO2": "LK",
    "CODEISO3": "LKA",
    "CODENUM3": "144"
  },
  {
    "COG": "99236",
    "ACTUAL": "4",
    "CAPAY": "99217",
    "CRPAY": "",
    "ANI": "1945",
    "LIBCOG": "TAIWAN",
    "LIBENR": "TAÏWAN",
    "ANCNOM": "FORMOSE",
    "CODEISO2": "TW",
    "CODEISO3": "TWN",
    "CODENUM3": "158"
  },
  {
    "COG": "99237",
    "ACTUAL": "2",
    "CAPAY": "99217",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "COREE",
    "LIBENR": "CORÉE",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99238",
    "ACTUAL": "1",
    "CAPAY": "99237",
    "CRPAY": "",
    "ANI": "1953",
    "LIBCOG": "COREE (REPUBLIQUE POPULAIRE DEMOCRATIQUE DE)",
    "LIBENR": "RÉPUBLIQUE POPULAIRE DÉMOCRATIQUE DE CORÉE",
    "ANCNOM": "",
    "CODEISO2": "KP",
    "CODEISO3": "PRK",
    "CODENUM3": "408"
  },
  {
    "COG": "99239",
    "ACTUAL": "1",
    "CAPAY": "99237",
    "CRPAY": "",
    "ANI": "1945",
    "LIBCOG": "COREE (REPUBLIQUE DE)",
    "LIBENR": "RÉPUBLIQUE DE CORÉE",
    "ANCNOM": "",
    "CODEISO2": "KR",
    "CODEISO3": "KOR",
    "CODENUM3": "410"
  },
  {
    "COG": "99240",
    "ACTUAL": "1",
    "CAPAY": "99221",
    "CRPAY": "",
    "ANI": "1961",
    "LIBCOG": "KOWEIT",
    "LIBENR": "ÉTAT DU KOWEÏT",
    "ANCNOM": "",
    "CODEISO2": "KW",
    "CODEISO3": "KWT",
    "CODENUM3": "414"
  },
  {
    "COG": "99241",
    "ACTUAL": "1",
    "CAPAY": "98505",
    "CRPAY": "",
    "ANI": "1949",
    "LIBCOG": "LAOS",
    "LIBENR": "RÉPUBLIQUE DÉMOCRATIQUE POPULAIRE LAO",
    "ANCNOM": "",
    "CODEISO2": "LA",
    "CODEISO3": "LAO",
    "CODENUM3": "418"
  },
  {
    "COG": "99242",
    "ACTUAL": "1",
    "CAPAY": "99216",
    "CRPAY": "",
    "ANI": "1945",
    "LIBCOG": "MONGOLIE",
    "LIBENR": "MONGOLIE",
    "ANCNOM": "MONGOLIE EXTERIEURE",
    "CODEISO2": "MN",
    "CODEISO3": "MNG",
    "CODENUM3": "496"
  },
  {
    "COG": "99243",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1976",
    "LIBCOG": "VIET NAM",
    "LIBENR": "RÉPUBLIQUE SOCIALISTE DU VIÊT NAM",
    "ANCNOM": "",
    "CODEISO2": "VN",
    "CODEISO3": "VNM",
    "CODENUM3": "704"
  },
  {
    "COG": "99244",
    "ACTUAL": "2",
    "CAPAY": "98506",
    "CRPAY": "99243",
    "ANI": "1954",
    "LIBCOG": "VIET NAM DU NORD",
    "LIBENR": "VIÊT NAM DU NORD",
    "ANCNOM": "TONKIN, ANNAM N.",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99245",
    "ACTUAL": "2",
    "CAPAY": "XXXXX",
    "CRPAY": "99243",
    "ANI": "1954",
    "LIBCOG": "VIET NAM DU SUD",
    "LIBENR": "VIÊT NAM DU SUD",
    "ANCNOM": "ANNAM S.,COCHINCHINE",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99246",
    "ACTUAL": "1",
    "CAPAY": "99213",
    "CRPAY": "",
    "ANI": "1972",
    "LIBCOG": "BANGLADESH",
    "LIBENR": "RÉPUBLIQUE POPULAIRE DU BANGLADESH",
    "ANCNOM": "PAKISTAN ORIENTAL",
    "CODEISO2": "BD",
    "CODEISO3": "BGD",
    "CODENUM3": "050"
  },
  {
    "COG": "99247",
    "ACTUAL": "1",
    "CAPAY": "99221",
    "CRPAY": "",
    "ANI": "1971",
    "LIBCOG": "EMIRATS ARABES UNIS",
    "LIBENR": "ÉMIRATS ARABES UNIS",
    "ANCNOM": "",
    "CODEISO2": "AE",
    "CODEISO3": "ARE",
    "CODENUM3": "784"
  },
  {
    "COG": "99248",
    "ACTUAL": "1",
    "CAPAY": "99221",
    "CRPAY": "",
    "ANI": "1971",
    "LIBCOG": "QATAR",
    "LIBENR": "ÉTAT DU QATAR",
    "ANCNOM": "",
    "CODEISO2": "QA",
    "CODEISO3": "QAT",
    "CODENUM3": "634"
  },
  {
    "COG": "99249",
    "ACTUAL": "1",
    "CAPAY": "99221",
    "CRPAY": "",
    "ANI": "1971",
    "LIBCOG": "BAHREIN",
    "LIBENR": "ÉTAT DE BAHREÏN",
    "ANCNOM": "",
    "CODEISO2": "BH",
    "CODEISO3": "BHR",
    "CODENUM3": "048"
  },
  {
    "COG": "99250",
    "ACTUAL": "1",
    "CAPAY": "99221",
    "CRPAY": "",
    "ANI": "1971",
    "LIBCOG": "OMAN",
    "LIBENR": "SULTANAT D'OMAN",
    "ANCNOM": "MASCATE-ET-OMAN",
    "CODEISO2": "OM",
    "CODEISO3": "OMN",
    "CODENUM3": "512"
  },
  {
    "COG": "99251",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1990",
    "LIBCOG": "YEMEN",
    "LIBENR": "RÉPUBLIQUE DU YÉMEN",
    "ANCNOM": "",
    "CODEISO2": "YE",
    "CODEISO3": "YEM",
    "CODENUM3": "887"
  },
  {
    "COG": "99252",
    "ACTUAL": "1",
    "CAPAY": "99123",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "ARMENIE",
    "LIBENR": "RÉPUBLIQUE D'ARMÉNIE",
    "ANCNOM": "",
    "CODEISO2": "AM",
    "CODEISO3": "ARM",
    "CODENUM3": "051"
  },
  {
    "COG": "99253",
    "ACTUAL": "1",
    "CAPAY": "99123",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "AZERBAIDJAN",
    "LIBENR": "RÉPUBLIQUE AZERBAÏDJANAISE",
    "ANCNOM": "",
    "CODEISO2": "AZ",
    "CODEISO3": "AZE",
    "CODENUM3": "031"
  },
  {
    "COG": "99254",
    "ACTUAL": "1",
    "CAPAY": "99143",
    "CRPAY": "",
    "ANI": "1960",
    "LIBCOG": "CHYPRE",
    "LIBENR": "RÉPUBLIQUE DE CHYPRE",
    "ANCNOM": "",
    "CODEISO2": "CY",
    "CODEISO3": "CYP",
    "CODENUM3": "196"
  },
  {
    "COG": "99255",
    "ACTUAL": "1",
    "CAPAY": "99123",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "GEORGIE",
    "LIBENR": "RÉPUBLIQUE DE GÉORGIE",
    "ANCNOM": "",
    "CODEISO2": "GE",
    "CODEISO3": "GEO",
    "CODENUM3": "268"
  },
  {
    "COG": "99256",
    "ACTUAL": "1",
    "CAPAY": "99123",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "KAZAKHSTAN",
    "LIBENR": "RÉPUBLIQUE DU KAZAKHSTAN",
    "ANCNOM": "",
    "CODEISO2": "KZ",
    "CODEISO3": "KAZ",
    "CODENUM3": "398"
  },
  {
    "COG": "99257",
    "ACTUAL": "1",
    "CAPAY": "99123",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "KIRGHIZISTAN",
    "LIBENR": "RÉPUBLIQUE KIRGHIZE",
    "ANCNOM": "",
    "CODEISO2": "KG",
    "CODEISO3": "KGZ",
    "CODENUM3": "417"
  },
  {
    "COG": "99258",
    "ACTUAL": "1",
    "CAPAY": "99123",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "OUZBEKISTAN",
    "LIBENR": "RÉPUBLIQUE D'OUZBÉKISTAN",
    "ANCNOM": "",
    "CODEISO2": "UZ",
    "CODEISO3": "UZB",
    "CODENUM3": "860"
  },
  {
    "COG": "99259",
    "ACTUAL": "1",
    "CAPAY": "99123",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "TADJIKISTAN",
    "LIBENR": "RÉPUBLIQUE DU TADJIKISTAN",
    "ANCNOM": "",
    "CODEISO2": "TJ",
    "CODEISO3": "TJK",
    "CODENUM3": "762"
  },
  {
    "COG": "99260",
    "ACTUAL": "1",
    "CAPAY": "99123",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "TURKMENISTAN",
    "LIBENR": "TURKMÉNISTAN",
    "ANCNOM": "",
    "CODEISO2": "TM",
    "CODEISO3": "TKM",
    "CODENUM3": "795"
  },
  {
    "COG": "99261",
    "ACTUAL": "1",
    "CAPAY": "99207",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "PALESTINE (Etat de)",
    "LIBENR": "ÉTAT DE PALESTINE",
    "ANCNOM": "PALESTINE (Territoire de)",
    "CODEISO2": "PS",
    "CODEISO3": "PSE",
    "CODENUM3": "275"
  },
  {
    "COG": "99262",
    "ACTUAL": "1",
    "CAPAY": "99231",
    "CRPAY": "",
    "ANI": "2002",
    "LIBCOG": "TIMOR ORIENTAL",
    "LIBENR": "RÉPUBLIQUE DÉMOCRATIQUE DU TIMOR ORIENTAL",
    "ANCNOM": "",
    "CODEISO2": "TL",
    "CODEISO3": "TLS",
    "CODENUM3": "626"
  },
  {
    "COG": "99301",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "EGYPTE",
    "LIBENR": "RÉPUBLIQUE ARABE D'ÉGYPTE",
    "ANCNOM": "",
    "CODEISO2": "EG",
    "CODEISO3": "EGY",
    "CODENUM3": "818"
  },
  {
    "COG": "99302",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "LIBERIA",
    "LIBENR": "RÉPUBLIQUE DU LIBERIA",
    "ANCNOM": "",
    "CODEISO2": "LR",
    "CODEISO3": "LBR",
    "CODENUM3": "430"
  },
  {
    "COG": "99303",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "AFRIQUE DU SUD",
    "LIBENR": "RÉPUBLIQUE D'AFRIQUE DU SUD",
    "ANCNOM": "UNION SUD-AFRICAINE",
    "CODEISO2": "ZA",
    "CODEISO3": "ZAF",
    "CODENUM3": "710"
  },
  {
    "COG": "99304",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1965",
    "LIBCOG": "GAMBIE",
    "LIBENR": "RÉPUBLIQUE DE GAMBIE",
    "ANCNOM": "",
    "CODEISO2": "GM",
    "CODEISO3": "GMB",
    "CODENUM3": "270"
  },
  {
    "COG": "99305",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "CAMEROUN ET TOGO",
    "LIBENR": "CAMEROUN ET TOGO",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99306",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99132",
    "ANI": "",
    "LIBCOG": "SAINTE HELENE, ASCENSION ET TRISTAN DA CUNHA",
    "LIBENR": "SAINTE-HÉLÈNE, ASCENSION ET TRISTAN DA CUNHA",
    "ANCNOM": "SAINTE HELENE",
    "CODEISO2": "SH",
    "CODEISO3": "SHN",
    "CODENUM3": "654"
  },
  {
    "COG": "99307",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "SOUDAN ANGLO-EGYPTIEN, KENYA, OUGANDA",
    "LIBENR": "SOUDAN ANGLO-ÉGYPTIEN, KENYA, OUGANDA",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99308",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "99309",
    "ANI": "",
    "LIBCOG": "ZANZIBAR",
    "LIBENR": "ZANZIBAR",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99308",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99132",
    "ANI": "",
    "LIBCOG": "OCEAN INDIEN (TERRITOIRE BRITANNIQUE DE L')",
    "LIBENR": "OCÉAN INDIEN (TERRITOIRE BRITANNIQUE DE L')",
    "ANCNOM": "",
    "CODEISO2": "IO",
    "CODEISO3": "IOT",
    "CODENUM3": "086"
  },
  {
    "COG": "99309",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1961",
    "LIBCOG": "TANZANIE",
    "LIBENR": "RÉPUBLIQUE UNIE DE TANZANIE",
    "ANCNOM": "TANGANYIKA, ZANZIBAR",
    "CODEISO2": "TZ",
    "CODEISO3": "TZA",
    "CODENUM3": "834"
  },
  {
    "COG": "99310",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1980",
    "LIBCOG": "ZIMBABWE",
    "LIBENR": "RÉPUBLIQUE DU ZIMBABWE",
    "ANCNOM": "RHODESIE DU SUD",
    "CODEISO2": "ZW",
    "CODEISO3": "ZWE",
    "CODENUM3": "716"
  },
  {
    "COG": "99311",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1990",
    "LIBCOG": "NAMIBIE",
    "LIBENR": "RÉPUBLIQUE DE NAMIBIE",
    "ANCNOM": "SUD-OUEST AFRICAIN",
    "CODEISO2": "NA",
    "CODEISO3": "NAM",
    "CODENUM3": "516"
  },
  {
    "COG": "99312",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1960",
    "LIBCOG": "CONGO (REPUBLIQUE DEMOCRATIQUE)",
    "LIBENR": "RÉPUBLIQUE DÉMOCRATIQUE DU CONGO",
    "ANCNOM": "ZAIRE",
    "CODEISO2": "CD",
    "CODEISO3": "COD",
    "CODENUM3": "180"
  },
  {
    "COG": "99313",
    "ACTUAL": "4",
    "CAPAY": "99314",
    "CRPAY": "99134",
    "ANI": "",
    "LIBCOG": "CANARIES (ILES)",
    "LIBENR": "CANARIES (ÎLES)",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99313",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99134",
    "ANI": "",
    "LIBCOG": "PROVINCES ESPAGNOLES D'AFRIQUE",
    "LIBENR": "PROVINCES ESPAGNOLES D'AFRIQUE",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99313",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99134",
    "ANI": "",
    "LIBCOG": "PRESIDES",
    "LIBENR": "PRÉSIDES",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99314",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1968",
    "LIBCOG": "GUINEE EQUATORIALE",
    "LIBENR": "RÉPUBLIQUE DE GUINÉE ÉQUATORIALE",
    "ANCNOM": "GUINEE ESPAGNOLE",
    "CODEISO2": "GQ",
    "CODEISO3": "GNQ",
    "CODENUM3": "226"
  },
  {
    "COG": "99315",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "ETHIOPIE",
    "LIBENR": "RÉPUBLIQUE DÉMOCRATIQUE FÉDÉRALE D'ÉTHIOPIE",
    "ANCNOM": "ABYSSINIE",
    "CODEISO2": "ET",
    "CODEISO3": "ETH",
    "CODENUM3": "231"
  },
  {
    "COG": "99316",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1951",
    "LIBCOG": "LIBYE",
    "LIBENR": "JAMAHIRIYA ARABE LIBYENNE POPULAIRE ET SOCIALISTE",
    "ANCNOM": "CYRENAIQUE",
    "CODEISO2": "LY",
    "CODEISO3": "LBY",
    "CODENUM3": "434"
  },
  {
    "COG": "99317",
    "ACTUAL": "1",
    "CAPAY": "99315",
    "CRPAY": "",
    "ANI": "1993",
    "LIBCOG": "ERYTHREE",
    "LIBENR": "ÉTAT D'ÉRYTHRÉE",
    "ANCNOM": "",
    "CODEISO2": "ER",
    "CODEISO3": "ERI",
    "CODENUM3": "232"
  },
  {
    "COG": "99318",
    "ACTUAL": "1",
    "CAPAY": "99308",
    "CRPAY": "",
    "ANI": "1951",
    "LIBCOG": "SOMALIE",
    "LIBENR": "SOMALIE",
    "ANCNOM": "",
    "CODEISO2": "SO",
    "CODEISO3": "SOM",
    "CODENUM3": "706"
  },
  {
    "COG": "99319",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99139",
    "ANI": "",
    "LIBCOG": "ACORES, MADERE",
    "LIBENR": "AÇORES, MADÈRE",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99320",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "ILES PORTUGAISES DE L'OCEAN INDIEN",
    "LIBENR": "ÎLES PORTUGAISES DE L'OCÉAN INDIEN",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99321",
    "ACTUAL": "1",
    "CAPAY": "99312",
    "CRPAY": "",
    "ANI": "1962",
    "LIBCOG": "BURUNDI",
    "LIBENR": "RÉPUBLIQUE DU BURUNDI",
    "ANCNOM": "URUNDI",
    "CODEISO2": "BI",
    "CODEISO3": "BDI",
    "CODENUM3": "108"
  },
  {
    "COG": "99322",
    "ACTUAL": "1",
    "CAPAY": "99305",
    "CRPAY": "",
    "ANI": "1961",
    "LIBCOG": "CAMEROUN",
    "LIBENR": "RÉPUBLIQUE DU CAMEROUN",
    "ANCNOM": "",
    "CODEISO2": "CM",
    "CODEISO3": "CMR",
    "CODENUM3": "120"
  },
  {
    "COG": "99323",
    "ACTUAL": "1",
    "CAPAY": "98303",
    "CRPAY": "",
    "ANI": "1960",
    "LIBCOG": "CENTRAFRICAINE (REPUBLIQUE)",
    "LIBENR": "RÉPUBLIQUE CENTRAFRICAINE",
    "ANCNOM": "OUBANGUI-CHARI",
    "CODEISO2": "CF",
    "CODEISO3": "CAF",
    "CODENUM3": "140"
  },
  {
    "COG": "99324",
    "ACTUAL": "1",
    "CAPAY": "98302",
    "CRPAY": "",
    "ANI": "1960",
    "LIBCOG": "CONGO",
    "LIBENR": "RÉPUBLIQUE DU CONGO",
    "ANCNOM": "CONGO-BRAZZAVILLE",
    "CODEISO2": "CG",
    "CODEISO3": "COG",
    "CODENUM3": "178"
  },
  {
    "COG": "99325",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "99350",
    "ANI": "",
    "LIBCOG": "TANGER",
    "LIBENR": "TANGER",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99326",
    "ACTUAL": "1",
    "CAPAY": "98204",
    "CRPAY": "",
    "ANI": "1960",
    "LIBCOG": "COTE D'IVOIRE",
    "LIBENR": "RÉPUBLIQUE DE CÔTE D'IVOIRE",
    "ANCNOM": "",
    "CODEISO2": "CI",
    "CODEISO3": "CIV",
    "CODENUM3": "384"
  },
  {
    "COG": "99327",
    "ACTUAL": "1",
    "CAPAY": "98205",
    "CRPAY": "",
    "ANI": "1960",
    "LIBCOG": "BENIN",
    "LIBENR": "RÉPUBLIQUE DU BÉNIN",
    "ANCNOM": "DAHOMEY",
    "CODEISO2": "BJ",
    "CODEISO3": "BEN",
    "CODENUM3": "204"
  },
  {
    "COG": "99328",
    "ACTUAL": "1",
    "CAPAY": "98301",
    "CRPAY": "",
    "ANI": "1960",
    "LIBCOG": "GABON",
    "LIBENR": "RÉPUBLIQUE GABONAISE",
    "ANCNOM": "",
    "CODEISO2": "GA",
    "CODEISO3": "GAB",
    "CODENUM3": "266"
  },
  {
    "COG": "99329",
    "ACTUAL": "1",
    "CAPAY": "99304",
    "CRPAY": "",
    "ANI": "1957",
    "LIBCOG": "GHANA",
    "LIBENR": "RÉPUBLIQUE DU GHANA",
    "ANCNOM": "COTE-DE-L'OR",
    "CODEISO2": "GH",
    "CODEISO3": "GHA",
    "CODENUM3": "288"
  },
  {
    "COG": "99330",
    "ACTUAL": "1",
    "CAPAY": "XXXXX",
    "CRPAY": "",
    "ANI": "1958",
    "LIBCOG": "GUINEE",
    "LIBENR": "RÉPUBLIQUE DE GUINÉE",
    "ANCNOM": "GUINEE FRANCAISE",
    "CODEISO2": "GN",
    "CODEISO3": "GIN",
    "CODENUM3": "324"
  },
  {
    "COG": "99331",
    "ACTUAL": "1",
    "CAPAY": "98209",
    "CRPAY": "",
    "ANI": "1960",
    "LIBCOG": "BURKINA",
    "LIBENR": "BURKINA FASO",
    "ANCNOM": "HAUTE-VOLTA",
    "CODEISO2": "BF",
    "CODEISO3": "BFA",
    "CODENUM3": "854"
  },
  {
    "COG": "99332",
    "ACTUAL": "1",
    "CAPAY": "99307",
    "CRPAY": "",
    "ANI": "1963",
    "LIBCOG": "KENYA",
    "LIBENR": "RÉPUBLIQUE DU KENYA",
    "ANCNOM": "",
    "CODEISO2": "KE",
    "CODEISO3": "KEN",
    "CODENUM3": "404"
  },
  {
    "COG": "99333",
    "ACTUAL": "1",
    "CAPAY": "98401",
    "CRPAY": "",
    "ANI": "1960",
    "LIBCOG": "MADAGASCAR",
    "LIBENR": "RÉPUBLIQUE DE MADAGASCAR",
    "ANCNOM": "",
    "CODEISO2": "MG",
    "CODEISO3": "MDG",
    "CODENUM3": "450"
  },
  {
    "COG": "99334",
    "ACTUAL": "1",
    "CAPAY": "99310",
    "CRPAY": "",
    "ANI": "1964",
    "LIBCOG": "MALAWI",
    "LIBENR": "RÉPUBLIQUE DU MALAWI",
    "ANCNOM": "NYASSALAND",
    "CODEISO2": "MW",
    "CODEISO3": "MWI",
    "CODENUM3": "454"
  },
  {
    "COG": "99335",
    "ACTUAL": "1",
    "CAPAY": "98206",
    "CRPAY": "",
    "ANI": "1960",
    "LIBCOG": "MALI",
    "LIBENR": "RÉPUBLIQUE DU MALI",
    "ANCNOM": "SOUDAN FRANCAIS",
    "CODEISO2": "ML",
    "CODEISO3": "MLI",
    "CODENUM3": "466"
  },
  {
    "COG": "99336",
    "ACTUAL": "1",
    "CAPAY": "98201",
    "CRPAY": "",
    "ANI": "1960",
    "LIBCOG": "MAURITANIE",
    "LIBENR": "RÉPUBLIQUE ISLAMIQUE DE MAURITANIE",
    "ANCNOM": "",
    "CODEISO2": "MR",
    "CODEISO3": "MRT",
    "CODENUM3": "478"
  },
  {
    "COG": "99337",
    "ACTUAL": "1",
    "CAPAY": "98207",
    "CRPAY": "",
    "ANI": "1960",
    "LIBCOG": "NIGER",
    "LIBENR": "RÉPUBLIQUE DU NIGER",
    "ANCNOM": "",
    "CODEISO2": "NE",
    "CODEISO3": "NER",
    "CODENUM3": "562"
  },
  {
    "COG": "99338",
    "ACTUAL": "1",
    "CAPAY": "99304",
    "CRPAY": "",
    "ANI": "1960",
    "LIBCOG": "NIGERIA",
    "LIBENR": "RÉPUBLIQUE FÉDÉRALE DU NIGÉRIA",
    "ANCNOM": "",
    "CODEISO2": "NG",
    "CODEISO3": "NGA",
    "CODENUM3": "566"
  },
  {
    "COG": "99339",
    "ACTUAL": "1",
    "CAPAY": "99307",
    "CRPAY": "",
    "ANI": "1962",
    "LIBCOG": "OUGANDA",
    "LIBENR": "RÉPUBLIQUE DE L'OUGANDA",
    "ANCNOM": "",
    "CODEISO2": "UG",
    "CODEISO3": "UGA",
    "CODENUM3": "800"
  },
  {
    "COG": "99340",
    "ACTUAL": "1",
    "CAPAY": "99312",
    "CRPAY": "",
    "ANI": "1962",
    "LIBCOG": "RWANDA",
    "LIBENR": "RÉPUBLIQUE RWANDAISE",
    "ANCNOM": "RUANDA",
    "CODEISO2": "RW",
    "CODEISO3": "RWA",
    "CODENUM3": "646"
  },
  {
    "COG": "99341",
    "ACTUAL": "1",
    "CAPAY": "98202",
    "CRPAY": "",
    "ANI": "1960",
    "LIBCOG": "SENEGAL",
    "LIBENR": "RÉPUBLIQUE DU SÉNÉGAL",
    "ANCNOM": "",
    "CODEISO2": "SN",
    "CODEISO3": "SEN",
    "CODENUM3": "686"
  },
  {
    "COG": "99342",
    "ACTUAL": "1",
    "CAPAY": "99304",
    "CRPAY": "",
    "ANI": "1961",
    "LIBCOG": "SIERRA LEONE",
    "LIBENR": "RÉPUBLIQUE DE SIERRA LEONE",
    "ANCNOM": "",
    "CODEISO2": "SL",
    "CODEISO3": "SLE",
    "CODENUM3": "694"
  },
  {
    "COG": "99343",
    "ACTUAL": "1",
    "CAPAY": "99307",
    "CRPAY": "",
    "ANI": "1956",
    "LIBCOG": "SOUDAN",
    "LIBENR": "RÉPUBLIQUE DU SOUDAN",
    "ANCNOM": "ANGLO-EGYPTIEN",
    "CODEISO2": "SD",
    "CODEISO3": "SDN",
    "CODENUM3": "729"
  },
  {
    "COG": "99344",
    "ACTUAL": "1",
    "CAPAY": "98304",
    "CRPAY": "",
    "ANI": "1960",
    "LIBCOG": "TCHAD",
    "LIBENR": "RÉPUBLIQUE DU TCHAD",
    "ANCNOM": "",
    "CODEISO2": "TD",
    "CODEISO3": "TCD",
    "CODENUM3": "148"
  },
  {
    "COG": "99345",
    "ACTUAL": "1",
    "CAPAY": "99305",
    "CRPAY": "",
    "ANI": "1960",
    "LIBCOG": "TOGO",
    "LIBENR": "RÉPUBLIQUE TOGOLAISE",
    "ANCNOM": "",
    "CODEISO2": "TG",
    "CODEISO3": "TGO",
    "CODENUM3": "768"
  },
  {
    "COG": "99346",
    "ACTUAL": "1",
    "CAPAY": "99310",
    "CRPAY": "",
    "ANI": "1964",
    "LIBCOG": "ZAMBIE",
    "LIBENR": "RÉPUBLIQUE DE ZAMBIE",
    "ANCNOM": "RHODESIE DU NORD",
    "CODEISO2": "ZM",
    "CODEISO3": "ZMB",
    "CODENUM3": "894"
  },
  {
    "COG": "99347",
    "ACTUAL": "1",
    "CAPAY": "99310",
    "CRPAY": "",
    "ANI": "1966",
    "LIBCOG": "BOTSWANA",
    "LIBENR": "RÉPUBLIQUE DU BOTSWANA",
    "ANCNOM": "BECHUANALAND",
    "CODEISO2": "BW",
    "CODEISO3": "BWA",
    "CODENUM3": "072"
  },
  {
    "COG": "99348",
    "ACTUAL": "1",
    "CAPAY": "99310",
    "CRPAY": "",
    "ANI": "1966",
    "LIBCOG": "LESOTHO",
    "LIBENR": "ROYAUME DU LESOTHO",
    "ANCNOM": "BASUTOLAND",
    "CODEISO2": "LS",
    "CODEISO3": "LSO",
    "CODENUM3": "426"
  },
  {
    "COG": "99349",
    "ACTUAL": "1",
    "CAPAY": "99343",
    "CRPAY": "",
    "ANI": "2011",
    "LIBCOG": "SOUDAN DU SUD",
    "LIBENR": "RÉPUBLIQUE DU SOUDAN DU SUD",
    "ANCNOM": "",
    "CODEISO2": "SS",
    "CODEISO3": "SSD",
    "CODENUM3": "728"
  },
  {
    "COG": "99350",
    "ACTUAL": "1",
    "CAPAY": "XXXXX",
    "CRPAY": "",
    "ANI": "1956",
    "LIBCOG": "MAROC",
    "LIBENR": "ROYAUME DU MAROC",
    "ANCNOM": "",
    "CODEISO2": "MA",
    "CODEISO3": "MAR",
    "CODENUM3": "504"
  },
  {
    "COG": "99351",
    "ACTUAL": "1",
    "CAPAY": "XXXXX",
    "CRPAY": "",
    "ANI": "1956",
    "LIBCOG": "TUNISIE",
    "LIBENR": "RÉPUBLIQUE TUNISIENNE",
    "ANCNOM": "",
    "CODEISO2": "TN",
    "CODEISO3": "TUN",
    "CODENUM3": "788"
  },
  {
    "COG": "99352",
    "ACTUAL": "1",
    "CAPAY": "XXXXX",
    "CRPAY": "",
    "ANI": "1962",
    "LIBCOG": "ALGERIE",
    "LIBENR": "RÉPUBLIQUE ALGÉRIENNE DÉMOCRATIQUE ET POPULAIRE",
    "ANCNOM": "",
    "CODEISO2": "DZ",
    "CODEISO3": "DZA",
    "CODENUM3": "012"
  },
  {
    "COG": "99389",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "SAHARA OCCIDENTAL",
    "LIBENR": "SAHARA OCCIDENTAL",
    "ANCNOM": "",
    "CODEISO2": "EH",
    "CODEISO3": "ESH",
    "CODENUM3": "732"
  },
  {
    "COG": "99390",
    "ACTUAL": "1",
    "CAPAY": "99308",
    "CRPAY": "",
    "ANI": "1968",
    "LIBCOG": "MAURICE",
    "LIBENR": "RÉPUBLIQUE DE MAURICE",
    "ANCNOM": "",
    "CODEISO2": "MU",
    "CODEISO3": "MUS",
    "CODENUM3": "480"
  },
  {
    "COG": "99391",
    "ACTUAL": "1",
    "CAPAY": "99310",
    "CRPAY": "",
    "ANI": "1968",
    "LIBCOG": "ESWATINI",
    "LIBENR": "ROYAUME D'ESWATINI",
    "ANCNOM": "SWAZILAND",
    "CODEISO2": "SZ",
    "CODEISO3": "SWZ",
    "CODENUM3": "748"
  },
  {
    "COG": "99392",
    "ACTUAL": "1",
    "CAPAY": "99319",
    "CRPAY": "",
    "ANI": "1974",
    "LIBCOG": "GUINEE-BISSAU",
    "LIBENR": "RÉPUBLIQUE DE GUINÉE-BISSAU",
    "ANCNOM": "GUINEE PORTUGAISE",
    "CODEISO2": "GW",
    "CODEISO3": "GNB",
    "CODENUM3": "624"
  },
  {
    "COG": "99393",
    "ACTUAL": "1",
    "CAPAY": "99320",
    "CRPAY": "",
    "ANI": "1975",
    "LIBCOG": "MOZAMBIQUE",
    "LIBENR": "RÉPUBLIQUE DU MOZAMBIQUE",
    "ANCNOM": "",
    "CODEISO2": "MZ",
    "CODEISO3": "MOZ",
    "CODENUM3": "508"
  },
  {
    "COG": "99394",
    "ACTUAL": "1",
    "CAPAY": "99319",
    "CRPAY": "",
    "ANI": "1975",
    "LIBCOG": "SAO TOME-ET-PRINCIPE",
    "LIBENR": "RÉPUBLIQUE DÉMOCRATIQUE DE SAO TOMÉ-ET-PRINCIPE",
    "ANCNOM": "",
    "CODEISO2": "ST",
    "CODEISO3": "STP",
    "CODENUM3": "678"
  },
  {
    "COG": "99395",
    "ACTUAL": "1",
    "CAPAY": "99319",
    "CRPAY": "",
    "ANI": "1975",
    "LIBCOG": "ANGOLA",
    "LIBENR": "RÉPUBLIQUE D'ANGOLA",
    "ANCNOM": "",
    "CODEISO2": "AO",
    "CODEISO3": "AGO",
    "CODENUM3": "024"
  },
  {
    "COG": "99396",
    "ACTUAL": "1",
    "CAPAY": "99319",
    "CRPAY": "",
    "ANI": "1975",
    "LIBCOG": "CAP-VERT",
    "LIBENR": "RÉPUBLIQUE DU CAP-VERT",
    "ANCNOM": "",
    "CODEISO2": "CV",
    "CODEISO3": "CPV",
    "CODENUM3": "132"
  },
  {
    "COG": "99397",
    "ACTUAL": "1",
    "CAPAY": "98402",
    "CRPAY": "",
    "ANI": "1975",
    "LIBCOG": "COMORES",
    "LIBENR": "RÉPUBLIQUE FÉDÉRALE ISLAMIQUE DES COMORES",
    "ANCNOM": "",
    "CODEISO2": "KM",
    "CODEISO3": "COM",
    "CODENUM3": "174"
  },
  {
    "COG": "99398",
    "ACTUAL": "1",
    "CAPAY": "99308",
    "CRPAY": "",
    "ANI": "1976",
    "LIBCOG": "SEYCHELLES",
    "LIBENR": "RÉPUBLIQUE DES SEYCHELLES",
    "ANCNOM": "",
    "CODEISO2": "SC",
    "CODEISO3": "SYC",
    "CODENUM3": "690"
  },
  {
    "COG": "99399",
    "ACTUAL": "1",
    "CAPAY": "98406",
    "CRPAY": "",
    "ANI": "1977",
    "LIBCOG": "DJIBOUTI",
    "LIBENR": "RÉPUBLIQUE DE DJIBOUTI",
    "ANCNOM": "T.F. AFARS ET ISSAS",
    "CODEISO2": "DJ",
    "CODEISO3": "DJI",
    "CODENUM3": "262"
  },
  {
    "COG": "99401",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "CANADA",
    "LIBENR": "CANADA",
    "ANCNOM": "",
    "CODEISO2": "CA",
    "CODEISO3": "CAN",
    "CODENUM3": "124"
  },
  {
    "COG": "99402",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "99401",
    "ANI": "",
    "LIBCOG": "TERRE-NEUVE",
    "LIBENR": "TERRE-NEUVE",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99403",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "99401",
    "ANI": "",
    "LIBCOG": "LABRADOR",
    "LIBENR": "LABRADOR",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99404",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "ETATS-UNIS",
    "LIBENR": "ÉTATS-UNIS D'AMÉRIQUE",
    "ANCNOM": "",
    "CODEISO2": "US",
    "CODEISO3": "USA",
    "CODENUM3": "840"
  },
  {
    "COG": "99404",
    "ACTUAL": "3",
    "CAPAY": "99432",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "ALASKA",
    "LIBENR": "ALASKA",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99405",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "MEXIQUE",
    "LIBENR": "ÉTATS-UNIS DU MEXIQUE",
    "ANCNOM": "",
    "CODEISO2": "MX",
    "CODEISO3": "MEX",
    "CODENUM3": "484"
  },
  {
    "COG": "99406",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "COSTA RICA",
    "LIBENR": "RÉPUBLIQUE DU COSTA RICA",
    "ANCNOM": "",
    "CODEISO2": "CR",
    "CODEISO3": "CRI",
    "CODENUM3": "188"
  },
  {
    "COG": "99407",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "CUBA",
    "LIBENR": "RÉPUBLIQUE DE CUBA",
    "ANCNOM": "",
    "CODEISO2": "CU",
    "CODEISO3": "CUB",
    "CODENUM3": "192"
  },
  {
    "COG": "99408",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "DOMINICAINE (REPUBLIQUE)",
    "LIBENR": "RÉPUBLIQUE DOMINICAINE",
    "ANCNOM": "SAINT-DOMINGUE",
    "CODEISO2": "DO",
    "CODEISO3": "DOM",
    "CODENUM3": "214"
  },
  {
    "COG": "99409",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "GUATEMALA",
    "LIBENR": "RÉPUBLIQUE DU GUATEMALA",
    "ANCNOM": "",
    "CODEISO2": "GT",
    "CODEISO3": "GTM",
    "CODENUM3": "320"
  },
  {
    "COG": "99410",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "HAITI",
    "LIBENR": "RÉPUBLIQUE D'HAÏTI",
    "ANCNOM": "",
    "CODEISO2": "HT",
    "CODEISO3": "HTI",
    "CODENUM3": "332"
  },
  {
    "COG": "99411",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "HONDURAS",
    "LIBENR": "RÉPUBLIQUE DU HONDURAS",
    "ANCNOM": "",
    "CODEISO2": "HN",
    "CODEISO3": "HND",
    "CODENUM3": "340"
  },
  {
    "COG": "99412",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "NICARAGUA",
    "LIBENR": "RÉPUBLIQUE DU NICARAGUA",
    "ANCNOM": "",
    "CODEISO2": "NI",
    "CODEISO3": "NIC",
    "CODENUM3": "558"
  },
  {
    "COG": "99413",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "PANAMA",
    "LIBENR": "RÉPUBLIQUE DU PANAMA",
    "ANCNOM": "",
    "CODEISO2": "PA",
    "CODEISO3": "PAN",
    "CODENUM3": "591"
  },
  {
    "COG": "99414",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "EL SALVADOR",
    "LIBENR": "RÉPUBLIQUE DU SALVADOR",
    "ANCNOM": "",
    "CODEISO2": "SV",
    "CODEISO3": "SLV",
    "CODENUM3": "222"
  },
  {
    "COG": "99415",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "ARGENTINE",
    "LIBENR": "RÉPUBLIQUE ARGENTINE",
    "ANCNOM": "",
    "CODEISO2": "AR",
    "CODEISO3": "ARG",
    "CODENUM3": "032"
  },
  {
    "COG": "99416",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "BRESIL",
    "LIBENR": "RÉPUBLIQUE FÉDÉRATIVE DU BRÉSIL",
    "ANCNOM": "",
    "CODEISO2": "BR",
    "CODEISO3": "BRA",
    "CODENUM3": "076"
  },
  {
    "COG": "99417",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "CHILI",
    "LIBENR": "RÉPUBLIQUE DU CHILI",
    "ANCNOM": "",
    "CODEISO2": "CL",
    "CODEISO3": "CHL",
    "CODENUM3": "152"
  },
  {
    "COG": "99418",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "BOLIVIE",
    "LIBENR": "ÉTAT PLURINATIONAL DE BOLIVIE",
    "ANCNOM": "REPUBLIQUE DE BOLIVIE",
    "CODEISO2": "BO",
    "CODEISO3": "BOL",
    "CODENUM3": "068"
  },
  {
    "COG": "99419",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "COLOMBIE",
    "LIBENR": "RÉPUBLIQUE DE COLOMBIE",
    "ANCNOM": "",
    "CODEISO2": "CO",
    "CODEISO3": "COL",
    "CODENUM3": "170"
  },
  {
    "COG": "99420",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "EQUATEUR",
    "LIBENR": "RÉPUBLIQUE DE L'ÉQUATEUR",
    "ANCNOM": "",
    "CODEISO2": "EC",
    "CODEISO3": "ECU",
    "CODENUM3": "218"
  },
  {
    "COG": "99421",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "PARAGUAY",
    "LIBENR": "RÉPUBLIQUE DU PARAGUAY",
    "ANCNOM": "",
    "CODEISO2": "PY",
    "CODEISO3": "PRY",
    "CODENUM3": "600"
  },
  {
    "COG": "99422",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "PEROU",
    "LIBENR": "RÉPUBLIQUE DU PÉROU",
    "ANCNOM": "",
    "CODEISO2": "PE",
    "CODEISO3": "PER",
    "CODENUM3": "604"
  },
  {
    "COG": "99423",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "URUGUAY",
    "LIBENR": "RÉPUBLIQUE ORIENTALE DE L'URUGUAY",
    "ANCNOM": "",
    "CODEISO2": "UY",
    "CODEISO3": "URY",
    "CODENUM3": "858"
  },
  {
    "COG": "99424",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "VENEZUELA",
    "LIBENR": "RÉPUBLIQUE BOLIVARIENNE DU VENEZUELA",
    "ANCNOM": "REPUBLIQUE DU VENEZUELA",
    "CODEISO2": "VE",
    "CODEISO3": "VEN",
    "CODENUM3": "862"
  },
  {
    "COG": "99425",
    "ACTUAL": "4",
    "CAPAY": "99426",
    "CRPAY": "99132",
    "ANI": "",
    "LIBCOG": "BERMUDES",
    "LIBENR": "BERMUDES",
    "ANCNOM": "",
    "CODEISO2": "BM",
    "CODEISO3": "BMU",
    "CODENUM3": "060"
  },
  {
    "COG": "99425",
    "ACTUAL": "4",
    "CAPAY": "99426",
    "CRPAY": "99132",
    "ANI": "",
    "LIBCOG": "CAIMANES (ILES)",
    "LIBENR": "CAÏMANES (ÎLES)",
    "ANCNOM": "",
    "CODEISO2": "KY",
    "CODEISO3": "CYM",
    "CODENUM3": "136"
  },
  {
    "COG": "99425",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99132",
    "ANI": "",
    "LIBCOG": "MONTSERRAT",
    "LIBENR": "MONTSERRAT",
    "ANCNOM": "",
    "CODEISO2": "MS",
    "CODEISO3": "MSR",
    "CODENUM3": "500"
  },
  {
    "COG": "99425",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99132",
    "ANI": "",
    "LIBCOG": "TERRITOIRES DU ROYAUME-UNI AUX ANTILLES",
    "LIBENR": "TERRITOIRES DU ROYAUME-UNI AUX ANTILLES",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99425",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99132",
    "ANI": "",
    "LIBCOG": "TURKS ET CAIQUES (ILES)",
    "LIBENR": "TURKS ET CAÏQUES (ÎLES)",
    "ANCNOM": "",
    "CODEISO2": "TC",
    "CODEISO3": "TCA",
    "CODENUM3": "796"
  },
  {
    "COG": "99425",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99132",
    "ANI": "",
    "LIBCOG": "VIERGES BRITANNIQUES (ILES)",
    "LIBENR": "VIERGES BRITANNIQUES (ÎLES)",
    "ANCNOM": "",
    "CODEISO2": "VG",
    "CODEISO3": "VGB",
    "CODENUM3": "092"
  },
  {
    "COG": "99425",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99132",
    "ANI": "",
    "LIBCOG": "ANGUILLA",
    "LIBENR": "ANGUILLA",
    "ANCNOM": "",
    "CODEISO2": "AI",
    "CODEISO3": "AIA",
    "CODENUM3": "660"
  },
  {
    "COG": "99426",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1962",
    "LIBCOG": "JAMAIQUE",
    "LIBENR": "JAMAÏQUE",
    "ANCNOM": "",
    "CODEISO2": "JM",
    "CODEISO3": "JAM",
    "CODENUM3": "388"
  },
  {
    "COG": "99427",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99132",
    "ANI": "",
    "LIBCOG": "TERR. DU ROYAUME-UNI DANS L'ATLANTIQUE SUD",
    "LIBENR": "TERR. DU ROYAUME-UNI DANS L'ATLANTIQUE_SUD",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99427",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99132",
    "ANI": "",
    "LIBCOG": "GEORGIE DU SUD ET LES ILES SANDWICH DU SUD",
    "LIBENR": "GÉORGIE DU SUD ET LES ÎLES SANDWICH DU SUD",
    "ANCNOM": "",
    "CODEISO2": "GS",
    "CODEISO3": "SGS",
    "CODENUM3": "239"
  },
  {
    "COG": "99427",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99132",
    "ANI": "",
    "LIBCOG": "MALOUINES, OU FALKLAND (ILES)",
    "LIBENR": "MALOUINES, OU FALKLAND (ÎLES)",
    "ANCNOM": "",
    "CODEISO2": "FK",
    "CODEISO3": "FLK",
    "CODENUM3": "238"
  },
  {
    "COG": "99428",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1966",
    "LIBCOG": "GUYANA",
    "LIBENR": "RÉPUBLIQUE COOPÉRATIVE DE GUYANA",
    "ANCNOM": "GUYANE BRITANNIQUE",
    "CODEISO2": "GY",
    "CODEISO3": "GUY",
    "CODENUM3": "328"
  },
  {
    "COG": "99429",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1981",
    "LIBCOG": "BELIZE",
    "LIBENR": "BELIZE",
    "ANCNOM": "HONDURAS BRITANNIQUE",
    "CODEISO2": "BZ",
    "CODEISO3": "BLZ",
    "CODENUM3": "084"
  },
  {
    "COG": "99430",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99101",
    "ANI": "",
    "LIBCOG": "GROENLAND",
    "LIBENR": "GROENLAND",
    "ANCNOM": "",
    "CODEISO2": "GL",
    "CODEISO3": "GRL",
    "CODENUM3": "304"
  },
  {
    "COG": "99431",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "99135",
    "ANI": "",
    "LIBCOG": "ANTILLES NEERLANDAISES",
    "LIBENR": "ANTILLES NÉERLANDAISES",
    "ANCNOM": "",
    "CODEISO2": "AN",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99432",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99404",
    "ANI": "",
    "LIBCOG": "PORTO RICO",
    "LIBENR": "PORTO RICO",
    "ANCNOM": "",
    "CODEISO2": "PR",
    "CODEISO3": "PRI",
    "CODENUM3": "630"
  },
  {
    "COG": "99432",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99404",
    "ANI": "",
    "LIBCOG": "TERR. DES ETATS-UNIS D'AMERIQUE EN AMERIQUE",
    "LIBENR": "TERR. DES ÉTATS-UNIS D'AMÉRIQUE EN AMÉRIQUE",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99432",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99404",
    "ANI": "",
    "LIBCOG": "VIERGES DES ETATS-UNIS (ILES)",
    "LIBENR": "VIERGES DES ÉTATS-UNIS (ÎLES)",
    "ANCNOM": "",
    "CODEISO2": "VI",
    "CODEISO3": "VIR",
    "CODENUM3": "850"
  },
  {
    "COG": "99433",
    "ACTUAL": "1",
    "CAPAY": "99426",
    "CRPAY": "",
    "ANI": "1962",
    "LIBCOG": "TRINITE-ET-TOBAGO",
    "LIBENR": "RÉPUBLIQUE DE TRINITÉ-ET-TOBAGO",
    "ANCNOM": "",
    "CODEISO2": "TT",
    "CODEISO3": "TTO",
    "CODENUM3": "780"
  },
  {
    "COG": "99434",
    "ACTUAL": "1",
    "CAPAY": "99425",
    "CRPAY": "",
    "ANI": "1966",
    "LIBCOG": "BARBADE",
    "LIBENR": "BARBADE",
    "ANCNOM": "",
    "CODEISO2": "BB",
    "CODEISO3": "BRB",
    "CODENUM3": "052"
  },
  {
    "COG": "99435",
    "ACTUAL": "1",
    "CAPAY": "99425",
    "CRPAY": "",
    "ANI": "1974",
    "LIBCOG": "GRENADE",
    "LIBENR": "GRENADE",
    "ANCNOM": "",
    "CODEISO2": "GD",
    "CODEISO3": "GRD",
    "CODENUM3": "308"
  },
  {
    "COG": "99436",
    "ACTUAL": "1",
    "CAPAY": "99425",
    "CRPAY": "",
    "ANI": "1973",
    "LIBCOG": "BAHAMAS",
    "LIBENR": "COMMONWEALTH DES BAHAMAS",
    "ANCNOM": "ILES  LUCAYES",
    "CODEISO2": "BS",
    "CODEISO3": "BHS",
    "CODENUM3": "044"
  },
  {
    "COG": "99437",
    "ACTUAL": "1",
    "CAPAY": "99431",
    "CRPAY": "",
    "ANI": "1975",
    "LIBCOG": "SURINAME",
    "LIBENR": "RÉPUBLIQUE DU SURINAME",
    "ANCNOM": "GUYANE NEERLANDAISE",
    "CODEISO2": "SR",
    "CODEISO3": "SUR",
    "CODENUM3": "740"
  },
  {
    "COG": "99438",
    "ACTUAL": "1",
    "CAPAY": "99425",
    "CRPAY": "",
    "ANI": "1978",
    "LIBCOG": "DOMINIQUE",
    "LIBENR": "COMMONWEALTH DE DOMINIQUE",
    "ANCNOM": "",
    "CODEISO2": "DM",
    "CODEISO3": "DMA",
    "CODENUM3": "212"
  },
  {
    "COG": "99439",
    "ACTUAL": "1",
    "CAPAY": "99425",
    "CRPAY": "",
    "ANI": "1979",
    "LIBCOG": "SAINTE-LUCIE",
    "LIBENR": "SAINTE-LUCIE",
    "ANCNOM": "",
    "CODEISO2": "LC",
    "CODEISO3": "LCA",
    "CODENUM3": "662"
  },
  {
    "COG": "99440",
    "ACTUAL": "1",
    "CAPAY": "99425",
    "CRPAY": "",
    "ANI": "1979",
    "LIBCOG": "SAINT-VINCENT-ET-LES GRENADINES",
    "LIBENR": "SAINT-VINCENT-ET-LES GRENADINES",
    "ANCNOM": "",
    "CODEISO2": "VC",
    "CODEISO3": "VCT",
    "CODENUM3": "670"
  },
  {
    "COG": "99441",
    "ACTUAL": "1",
    "CAPAY": "99425",
    "CRPAY": "",
    "ANI": "1981",
    "LIBCOG": "ANTIGUA-ET-BARBUDA",
    "LIBENR": "ANTIGUA-ET-BARBUDA",
    "ANCNOM": "",
    "CODEISO2": "AG",
    "CODEISO3": "ATG",
    "CODENUM3": "028"
  },
  {
    "COG": "99442",
    "ACTUAL": "1",
    "CAPAY": "99425",
    "CRPAY": "",
    "ANI": "1983",
    "LIBCOG": "SAINT-CHRISTOPHE-ET-NIEVES",
    "LIBENR": "FÉDÉRATION DE SAINT-CHRISTOPHE-ET-NIÉVÈS",
    "ANCNOM": "",
    "CODEISO2": "KN",
    "CODEISO3": "KNA",
    "CODENUM3": "659"
  },
  {
    "COG": "99443",
    "ACTUAL": "1",
    "CAPAY": "99431",
    "CRPAY": "",
    "ANI": "2010",
    "LIBCOG": "BONAIRE, SAINT EUSTACHE ET SABA",
    "LIBENR": "BONAIRE, SAINT EUSTACHE ET SABA",
    "ANCNOM": "",
    "CODEISO2": "BQ",
    "CODEISO3": "BES",
    "CODENUM3": "535"
  },
  {
    "COG": "99444",
    "ACTUAL": "1",
    "CAPAY": "99431",
    "CRPAY": "",
    "ANI": "2010",
    "LIBCOG": "CURAÇAO",
    "LIBENR": "CURAÇAO",
    "ANCNOM": "",
    "CODEISO2": "CW",
    "CODEISO3": "CUW",
    "CODENUM3": "531"
  },
  {
    "COG": "99445",
    "ACTUAL": "1",
    "CAPAY": "99431",
    "CRPAY": "",
    "ANI": "2010",
    "LIBCOG": "SAINT-MARTIN (PARTIE NEERLANDAISE)",
    "LIBENR": "SAINT-MARTIN (PARTIE NÉERLANDAISE)",
    "ANCNOM": "",
    "CODEISO2": "SX",
    "CODEISO3": "SXM",
    "CODENUM3": "534"
  },
  {
    "COG": "99501",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "AUSTRALIE",
    "LIBENR": "AUSTRALIE",
    "ANCNOM": "",
    "CODEISO2": "AU",
    "CODEISO3": "AUS",
    "CODENUM3": "036"
  },
  {
    "COG": "99501",
    "ACTUAL": "3",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "HEARD ET MACDONALD (ILES)",
    "LIBENR": "HEARD ET MACDONALD (ÎLES)",
    "ANCNOM": "",
    "CODEISO2": "HM",
    "CODEISO3": "HMD",
    "CODENUM3": "334"
  },
  {
    "COG": "99501",
    "ACTUAL": "3",
    "CAPAY": "99229",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "CHRISTMAS (ILE)",
    "LIBENR": "CHRISTMAS (ÎLE)",
    "ANCNOM": "",
    "CODEISO2": "CX",
    "CODEISO3": "CXR",
    "CODENUM3": "162"
  },
  {
    "COG": "99501",
    "ACTUAL": "3",
    "CAPAY": "99229",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "COCOS ou KEELING (ILES)",
    "LIBENR": "COCOS OU KEELING (ÎLES)",
    "ANCNOM": "",
    "CODEISO2": "CC",
    "CODEISO3": "CCK",
    "CODENUM3": "166"
  },
  {
    "COG": "99501",
    "ACTUAL": "3",
    "CAPAY": "99503",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "NORFOLK (ILE)",
    "LIBENR": "NORFOLK (ÎLE)",
    "ANCNOM": "",
    "CODEISO2": "NF",
    "CODEISO3": "NFK",
    "CODENUM3": "574"
  },
  {
    "COG": "99502",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "NOUVELLE-ZELANDE",
    "LIBENR": "NOUVELLE-ZÉLANDE",
    "ANCNOM": "",
    "CODEISO2": "NZ",
    "CODEISO3": "NZL",
    "CODENUM3": "554"
  },
  {
    "COG": "99502",
    "ACTUAL": "3",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "NIUE",
    "LIBENR": "NIUE",
    "ANCNOM": "",
    "CODEISO2": "NU",
    "CODEISO3": "NIU",
    "CODENUM3": "570"
  },
  {
    "COG": "99502",
    "ACTUAL": "3",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "TOKELAU",
    "LIBENR": "TOKELAU",
    "ANCNOM": "",
    "CODEISO2": "TK",
    "CODEISO3": "TKL",
    "CODENUM3": "772"
  },
  {
    "COG": "99502",
    "ACTUAL": "3",
    "CAPAY": "99503",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "COOK (ILES)",
    "LIBENR": "COOK (ÎLES)",
    "ANCNOM": "",
    "CODEISO2": "CK",
    "CODEISO3": "COK",
    "CODENUM3": "184"
  },
  {
    "COG": "99503",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99132",
    "ANI": "",
    "LIBCOG": "PITCAIRN (ILE)",
    "LIBENR": "PITCAIRN (ÎLE)",
    "ANCNOM": "",
    "CODEISO2": "PN",
    "CODEISO3": "PCN",
    "CODENUM3": "612"
  },
  {
    "COG": "99504",
    "ACTUAL": "2",
    "CAPAY": "",
    "CRPAY": "99404",
    "ANI": "",
    "LIBCOG": "HAWAII (ILES)",
    "LIBENR": "HAWAII (ÎLES)",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99505",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99404",
    "ANI": "",
    "LIBCOG": "TERR. DES ETATS-UNIS D'AMERIQUE EN OCEANIE",
    "LIBENR": "TERR. DES ÉTATS-UNIS D'AMÉRIQUE EN OCÉANIE",
    "ANCNOM": "",
    "CODEISO2": "",
    "CODEISO3": "",
    "CODENUM3": ""
  },
  {
    "COG": "99505",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99404",
    "ANI": "",
    "LIBCOG": "MARIANNES DU NORD (ILES)",
    "LIBENR": "MARIANNES DU NORD (ÎLES)",
    "ANCNOM": "",
    "CODEISO2": "MP",
    "CODEISO3": "MNP",
    "CODENUM3": "580"
  },
  {
    "COG": "99505",
    "ACTUAL": "4",
    "CAPAY": "",
    "CRPAY": "99404",
    "ANI": "",
    "LIBCOG": "SAMOA AMERICAINES",
    "LIBENR": "SAMOA AMÉRICAINES",
    "ANCNOM": "",
    "CODEISO2": "AS",
    "CODEISO3": "ASM",
    "CODENUM3": "016"
  },
  {
    "COG": "99505",
    "ACTUAL": "4",
    "CAPAY": "99220",
    "CRPAY": "99404",
    "ANI": "",
    "LIBCOG": "GUAM",
    "LIBENR": "GUAM",
    "ANCNOM": "",
    "CODEISO2": "GU",
    "CODEISO3": "GUM",
    "CODENUM3": "316"
  },
  {
    "COG": "99506",
    "ACTUAL": "1",
    "CAPAY": "99505",
    "CRPAY": "",
    "ANI": "1962",
    "LIBCOG": "SAMOA OCCIDENTALES",
    "LIBENR": "ÉTAT INDÉPENDANT DES SAMOA OCCIDENTALES",
    "ANCNOM": "",
    "CODEISO2": "WS",
    "CODEISO3": "WSM",
    "CODENUM3": "882"
  },
  {
    "COG": "99507",
    "ACTUAL": "1",
    "CAPAY": "99501",
    "CRPAY": "",
    "ANI": "1968",
    "LIBCOG": "NAURU",
    "LIBENR": "RÉPUBLIQUE DE NAURU",
    "ANCNOM": "",
    "CODEISO2": "NR",
    "CODEISO3": "NRU",
    "CODENUM3": "520"
  },
  {
    "COG": "99508",
    "ACTUAL": "1",
    "CAPAY": "99503",
    "CRPAY": "",
    "ANI": "1970",
    "LIBCOG": "FIDJI",
    "LIBENR": "RÉPUBLIQUE DES FIDJI",
    "ANCNOM": "",
    "CODEISO2": "FJ",
    "CODEISO3": "FJI",
    "CODENUM3": "242"
  },
  {
    "COG": "99509",
    "ACTUAL": "1",
    "CAPAY": "99503",
    "CRPAY": "",
    "ANI": "1970",
    "LIBCOG": "TONGA",
    "LIBENR": "ROYAUME DES TONGA",
    "ANCNOM": "",
    "CODEISO2": "TO",
    "CODEISO3": "TON",
    "CODENUM3": "776"
  },
  {
    "COG": "99510",
    "ACTUAL": "1",
    "CAPAY": "99501",
    "CRPAY": "",
    "ANI": "1975",
    "LIBCOG": "PAPOUASIE-NOUVELLE-GUINEE",
    "LIBENR": "PAPOUASIE-NOUVELLE-GUINÉE",
    "ANCNOM": "",
    "CODEISO2": "PG",
    "CODEISO3": "PNG",
    "CODENUM3": "598"
  },
  {
    "COG": "99511",
    "ACTUAL": "1",
    "CAPAY": "99503",
    "CRPAY": "",
    "ANI": "1978",
    "LIBCOG": "TUVALU",
    "LIBENR": "TUVALU",
    "ANCNOM": "ILES ELLICE",
    "CODEISO2": "TV",
    "CODEISO3": "TUV",
    "CODENUM3": "798"
  },
  {
    "COG": "99512",
    "ACTUAL": "1",
    "CAPAY": "99503",
    "CRPAY": "",
    "ANI": "1978",
    "LIBCOG": "SALOMON (ILES)",
    "LIBENR": "ÎLES SALOMON",
    "ANCNOM": "ILES SALOMON DU SUD",
    "CODEISO2": "SB",
    "CODEISO3": "SLB",
    "CODENUM3": "090"
  },
  {
    "COG": "99513",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "1979",
    "LIBCOG": "KIRIBATI",
    "LIBENR": "RÉPUBLIQUE DE KIRIBATI",
    "ANCNOM": "ILES GILBERT",
    "CODEISO2": "KI",
    "CODEISO3": "KIR",
    "CODENUM3": "296"
  },
  {
    "COG": "99514",
    "ACTUAL": "1",
    "CAPAY": "99503",
    "CRPAY": "",
    "ANI": "1980",
    "LIBCOG": "VANUATU",
    "LIBENR": "RÉPUBLIQUE DE VANUATU",
    "ANCNOM": "NOUVELLES-HEBRIDES",
    "CODEISO2": "VU",
    "CODEISO3": "VUT",
    "CODENUM3": "548"
  },
  {
    "COG": "99515",
    "ACTUAL": "1",
    "CAPAY": "99505",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "MARSHALL (ILES)",
    "LIBENR": "RÉPUBLIQUE DES ÎLES MARSHALL",
    "ANCNOM": "",
    "CODEISO2": "MH",
    "CODEISO3": "MHL",
    "CODENUM3": "584"
  },
  {
    "COG": "99516",
    "ACTUAL": "1",
    "CAPAY": "99505",
    "CRPAY": "",
    "ANI": "1991",
    "LIBCOG": "MICRONESIE (ETATS FEDERES DE)",
    "LIBENR": "ÉTATS FÉDÉRÉS DE MICRONÉSIE",
    "ANCNOM": "",
    "CODEISO2": "FM",
    "CODEISO3": "FSM",
    "CODENUM3": "583"
  },
  {
    "COG": "99517",
    "ACTUAL": "1",
    "CAPAY": "99404",
    "CRPAY": "",
    "ANI": "1994",
    "LIBCOG": "PALAOS (ILES)",
    "LIBENR": "RÉPUBLIQUE DES ÎLES PALAOS",
    "ANCNOM": "BELAU",
    "CODEISO2": "PW",
    "CODEISO3": "PLW",
    "CODENUM3": "585"
  },
  {
    "COG": "99100",
    "ACTUAL": "1",
    "CAPAY": "",
    "CRPAY": "",
    "ANI": "",
    "LIBCOG": "FRANCE",
    "LIBENR": "RÉPUBLIQUE FRANÇAISE",
    "ANCNOM": "",
    "CODEISO2": "FR",
    "CODEISO3": "FRA",
    "CODENUM3": "250"
  },
]


const CodePaysINSEEValues = CodePays.map(({ COG, LIBCOG }) => {
  return {
    value: COG,
    label: LIBCOG,
  }
})

const CodePaysINSEEKeys = CodePays.map(({ COG }) => COG)

export {
  CodePays,
  CodePaysINSEEKeys,
  CodePaysINSEEValues,
}