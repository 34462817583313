const CodeVoie = {
  'ALL': 'Allée',
  'AV': 'Avenue',
  'BAT': 'Batiment',
  'BD': 'Boulevard',
  'C': 'Cours',
  'CAN': 'Canal',
  'CHE': 'Chemin',
  'CHS': 'Chaussée',
  'CI': 'Cité',
  'CL': 'Clos',
  'COG': 'Cottage',
  'FG': 'Faubourg',
  'HAM': 'Hameau',
  'IMB': 'Immeuble',
  'IMP': 'Impasse',
  'LOT': 'Lotissement',
  'MAI': 'Maison',
  'MTE': 'Montée',
  'PAR': 'Parc',
  'PAS': 'Passage',
  'PAV': 'Pavillon',
  'PL': 'Place',
  'POR': 'Porte',
  'Q': 'Quai',
  'QU': 'Quartier',
  'R': 'Rue',
  'RAM': 'Rampe',
  'RES': 'Résidence',
  'RTE': 'Route',
  'RUL': 'Ruelle',
  'SEN': 'Sentier',
  'SQ': 'Square',
  'VO': 'Voie',
  CAS: 'CASERNE',
  CAV: 'CAVEE',
  CEI: 'CEINTURE',
  CEL: 'CELLIER',
  CEN: 'CENTRE',
  CGE: 'COLLEGE',
  CH: 'CHASSE',
  CHA: 'CHATEAU',
  CHN: 'CHANTIER',
  CHP: 'CHAPELLE',
  CHT: 'CHEMINEMENT',
  CHV: 'CHEMIN VICINAL',
  CIM: 'CIMETIERE',
  CIN: 'CINEMA',
  CLB: 'CLUB',
  CLI: 'CLINIQUE',
  CLL: 'COL',
  CLO: 'CLOITRE',
  CN: 'COIN',
  CO: 'COUR',
  COE: 'COLLINE',
  COL: 'COLONIE',
  COM: 'COMMISSARIAT',
  COR: 'CORNICHE',
  COT: 'COTE',
  COU: 'COULOIR',
  CRO: 'CARROI',
  CRX: 'CROIX',
  CTE: 'CENTRAL TELEPHONIQUE',
  CUR: 'CURE',
  DEG: 'DEGRE',
  DEP: 'DEPOT',
  DES: 'DESCENTE',
  DIG: 'DIGUE',
  DOM: 'DOMAINE',
  DON: 'DIRECTION',
  ECA: 'ECART',
  ECL: 'ECLUSE',
  ECO: 'ECOLE',
  ECV: 'ENCLAVE',
  EGL: 'EGLISE',
  EMP: 'EMPLACEMENT',
  EN: 'ENCEINTE',
  ENC: 'ENCLOS',
  ENT: 'ENTREPOT',
  ESC: 'ESCALIER',
  ESP: 'ESPLANADE',
  ETA: 'ETABLISSEMENT',
  ETG: 'ETANG',
  FAC: 'FACADE',
  FCU: 'FACULTE',
  FER: 'FERME',
  FOL: 'FOLIE',
  FON: 'FONTAINE',
  FOR: 'FORET',
  FOS: 'FOSSE',
  FOY: 'FOYER',
  FRI: 'FRICHE',
  FT: 'FORT',
  GA: 'GARE',
  GAL: 'GALERIE',
  GDI: 'GRAND IMPASSE',
  GED: 'GENDARMERIE',
  GEN: 'GRAND ENSEMBLE',
  GGE: 'GARAGE',
  GLA: 'GLACIS',
  GPE: 'GROUPE',
  GR: 'GRAND RUE',
  GRI: 'GRILLE',
  GRN: 'GARENNE',
  GRP: 'GROUPEMENT',
  GSC: 'GROUPE SCOLAIRE',
  GUE: 'GUE',
  HA: 'HALAGE',
  HAL: 'HALLE',
  HCE: 'HOSPICE',
  HIP: 'HIPPODROME',
  HLM: 'H.L.M.',
  HOP: 'HOPITAL',
  HOT: 'HOTEL',
  HUT: 'HUTTE',
  ILE: 'ILE',
  ILO: 'ILOT',
  INS: 'INSTITUT',
  ISP: 'INSPECTION',
  JAR: 'JARDIN',
  JET: 'JETEE',
  JUS: 'JUSTICE',
  LD: 'LIEU DIT',
  LEV: 'LEVEE',
  LYC: 'LYCEE',
  MA: 'MARE',
  MAG: 'MAGASIN',
  MAN: 'MANOIR',
  MAR: 'MARCHE',
  MAS: 'MAS',
  MAT: 'MATERNITE',
  MET: 'METRO',
  MF: 'MAISON FORESTIERE',
  MG: 'MAISON DE GARDE',
  ML: 'MAIL',
  MOU: 'MOULIN',
  MRI: 'MAIRIE',
  MUS: 'MUSEE',
  NTE: 'NOUVELLE ROUTE',
  OBS: 'OBSERVATOIRE',
  OCT: 'OCTROI',
  PA: 'PARVIS',
  PAE: 'PETITE AVENUE',
  PAL: 'PALAIS',
  PAT: 'PATURE',
  PBY: 'PRESBYTERE',
  PCE: 'PORCHE',
  PER: 'PERYSTILE',
  PHA: 'PHARMACIE',
  PIE: 'PIECE',
  PIM: 'PETITE IMPASSE',
  PKG: 'PARKING',
  PLA: 'PLAINE',
  PLC: 'PLACIS',
  PLE: 'PASSERELLE',
  PLT: 'PLATEAU',
  PLY: 'PETIT LYCEE',
  PO: 'PONT',
  POI: 'POINTE',
  POT: 'POTERNE',
  POU: 'POURTOUR',
  PPA: 'PETIT PASSAGE',
  PR: 'PETITE RUE',
  PRA: 'PRAIRIE',
  PRE: 'PRE',
  PRO: 'PROMENADE',
  PRQ: 'PRESQUILE',
  PRT: 'PETITE ROUTE',
  PRU: 'PREAU',
  PT: 'PORT',
  PTE: 'POSTE',
  RAC: 'RACCOURCI',
  REM: 'REMPART',
  RNT: 'RESTAURANT',
  ROC: 'ROCADE',
  ROQ: 'ROQUET',
  ROT: 'ROTONDE',
  ROU: 'ROULADE',
  RP: 'ROND POINT',
  SAN: 'SANATORIUM',
  SAP: 'SAPUM',
  SAS: 'SAS',
  SCE: 'SERVICE',
  SEC: 'SECTION',
  SEM: 'SEMINAIRE',
  SNT: 'SENTE',
  SOC: 'SOCIETE',
  STA: 'STADE',
  STN: 'STAND',
  STO: 'STATION',
  TE: 'TERRASSE',
  TEA: 'TERRAIN',
  TEN: 'TENUE',
  TER: 'TERTRE',
  THE: 'THEATRE',
  TOU: 'TOUR',
  TP: 'TERRE PLEIN',
  TRA: 'TRAVERSE',
  TRG: 'TRIEGE',
  TRI: 'TRIAGE',
  TUI: 'TUILERIE',
  USI: 'USINE',
  VAL: 'VAL',
  VCH: 'VIEUX CHEMIN',
  VEL: 'VELODROME',
  VEN: 'VENELLE',
  VGE: 'VILLAGE',
  VIA: 'VIADUC',
  VIL: 'VILLE',
  VLA: 'VILLA',
  VLE: 'VALLEE',
  VNO: 'VILLE NOUVELLE',
  VON: 'VALLON',
  VOY: 'VOYEUL',
  ZAC: 'ZAC',
  ZI: 'ZONE INDUSTRIELLE',
  ZUP: 'Z.U.P.',
}

const CodeVoieKeys = Object.keys(CodeVoie)
const CodeVoieValues = Object.keys(CodeVoie).map((key) => ({
  label: CodeVoie[key],
  value: key,
}))

export {
  CodeVoie,
  CodeVoieKeys,
  CodeVoieValues,
}