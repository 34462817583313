const CodeNumeroVoie = {
  'A': 'A',
  // 'B': 'B',
  // 'C': 'C',
  'D': 'D',
  'B': 'B / Bis',
  'T': 'Ter',
  'Q': 'Quater',
  'C': 'C / Cinquième',
}

const CodeNumeroVoieKeys = Object.keys(CodeNumeroVoie)
const CodeNumeroVoieValues = Object.keys(CodeNumeroVoie).map((key) => ({
 label: CodeNumeroVoie[key],
 value: key,
}))

export {
  CodeNumeroVoie,
  CodeNumeroVoieKeys,
  CodeNumeroVoieValues,
}