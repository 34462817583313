import * as Yup from 'yup';

import { CodeNumeroVoieKeys } from '../data/CodeNumeroVoie'
import { CodeVoieKeys } from '../data/CodeVoie'
import { CodePaysINSEEKeys } from '../data/CodePays'

const PL = 'a-zA-Z'

const Nomgex = new RegExp(`^([${PL}]+([${PL}'\\- ]+[${PL}])*)?$`)
const PrenomsRegex = new RegExp(`^[${PL}]+(['\\-]*[${PL}]+)*((\\ ){1}[${PL}]+(['\\-]*[${PL}]+)*)*$`)

const YopSchemaInputCommuneDTO = Yup.object().shape({
  codeCommune: Yup
    .string()
    .notRequired(),
    // .oneOf(CodeCommuneKeys, 'Commune obligatoire')
    // .required('Commune obligatoire'),
    // libelleCommune: Yup.string().required('Libellé commune obligatoire'),


  libelleCommune: Yup
    .mixed()
    .notRequired()
    .test({
      name: 'libelleCommuneNaissance',
      message: 'Libellé commune obligatoire',
      test (value, params) {
        const { options, parent } = params
        const codePaysNaissance = (options?.context?.lieuNaissance ?? parent)?.codePaysNaissance
        const codePaysNaissanceFrance = codePaysNaissance === '99100'
        return !codePaysNaissanceFrance || !!value
      }
    }),
})

const YopSchemaInputLieuNaissanceDTO = Yup.object().shape({
  codePaysNaissance: Yup
    .string()
    .required('Le pays de naissance est obligatoire')
    .oneOf(CodePaysINSEEKeys, 'Le pays est obligatoire'),

  departementNaissance: Yup
    .string()
    // .nullable()
    .test({
      name: 'Required_DepartementNaissance',
      message: 'Le département est obligatoire',
      test (value, params) {
        const { options, parent } = params
        const codePaysNaissance = (options?.context?.lieuNaissance ?? parent)?.codePaysNaissance
        const codePaysNaissanceFrance = codePaysNaissance === '99100'
        return !codePaysNaissanceFrance || !!value
      },
    })
    .test('departement', 'Numéro de département invalide', function (value) {
      if (!value) return true
      if (!value || value.length > 3) { return false }
      if (value === '02A' || value === '02B' || value === '2A' || value === '2B') { return true }
      const intVal = parseInt(value, 10)
      if (isNaN(intVal) || isNaN(value)) { return false }
      // return (intVal >= 1 && intVal <= 95) || (intVal >= 971 && intVal <= 976)
      return (intVal >= 1 )
    }),

  communeNaissance: YopSchemaInputCommuneDTO.required()
    
})

const YupSchemeInputAdresseDTO = Yup.object().shape({
  numeroVoie: Yup
    .string()
    .max(20, 'Le numéro de voie ne peut pas dépasser 20 caractères')
    .matches(/^(?!^0$)([0-9]){0,20}$/,'Numéro de voie invalide')
    .notOneOf(Array(20 - 1).fill().map((_,  i) => Array(i + 1).fill('0').join('')), 'Numéro de voie invalide'),

  lettreVoie: Yup
    .string()
    .notRequired()
    .oneOf([...CodeNumeroVoieKeys, '', null], '')
    .nullable(),

  codeTypeVoie: Yup
    .string()
    .oneOf([...CodeVoieKeys, '', null], '')
    .notRequired()
    .nullable()
    .test({
      name: 'Required_CodeTypeVoie',
      message: 'Le type de voie est obligatoire',
      test (value, params) {
        const { options, parent } = params
        const hasLibelleVoie = (options?.context?.adressePostale ?? parent)?.libelleVoie
        return !hasLibelleVoie || !!value
      },
    }),

  libelleVoie: Yup
    .string()
    .max(28, 'Le libellé de voie ne peut pas dépasser 28 caractères')
    .test({
      name: 'Required_LibelleVoix',
      message: 'Le libellé de voie est obligatoire',
      test (value, params) {
        const { options } = params
        const numeroVoie = options?.context?.adressePostale?.numeroVoie
        return !numeroVoie || !!value
      },
    })
    .test({
      name: 'RequireVoieCompltLieuDit1',
      message: 'Le libelle de la voie et/ou le complément et/ou le lieu-dit doit être renseigné',
      test (value, params) {
        const { options, parent } = params
        const hasLibelleVoie = (options?.context?.adressePostale ?? parent)?.libelleVoie
        const hasComplement = (options?.context?.adressePostale ?? parent)?.complement
        const hasLieudit = (options?.context?.adressePostale ?? parent)?.lieuDit
        console.log('RequireVoieCompltLieuDit1')
        console.log({value, params, hasLibelleVoie, hasComplement, hasLieudit })
        return hasLibelleVoie || hasComplement || hasLieudit
      }
    }),

  complement: Yup
    .string()
    .max(38, 'Le complément ne peut pas dépasser 38 caractères')
    .test({
      name: 'RequireVoieCompltLieuDit2',
      message: 'Le libelle de la voie et/ou le complément et/ou le lieu-dit doit être renseigné',
      test (value, params) {
        const { options, parent } = params
        const hasLibelleVoie = (options?.context?.adressePostale ?? parent)?.libelleVoie
        const hasComplement = (options?.context?.adressePostale ?? parent)?.complement
        const hasLieudit = (options?.context?.adressePostale ?? parent)?.lieuDit
        console.log('RequireVoieCompltLieuDit2')
        console.log({value, params, hasLibelleVoie, hasComplement, hasLieudit })
        return hasLibelleVoie || hasComplement || hasLieudit
      }
    }),

  lieuDit: Yup
    .string()
    .max(38, 'Le lieu dit ne peut pas dépasser 38 caractères')
    .test({
      name: 'RequireVoieCompltLieuDit3',
      message: 'Le libelle de la voie et/ou le complément et/ou le lieu-dit doit être renseigné',
      test (value, params) {
        const { options, parent } = params
        const hasLibelleVoie = (options?.context?.adressePostale ?? parent)?.libelleVoie
        const hasComplement = (options?.context?.adressePostale ?? parent)?.complement
        const hasLieudit = (options?.context?.adressePostale ?? parent)?.lieuDit
        console.log('RequireVoieCompltLieuDit3')
        console.log({value, params, hasLibelleVoie, hasComplement, hasLieudit })
        return hasLibelleVoie || hasComplement || hasLieudit
      }
    }),

  libelleCommune: Yup
    .mixed()
    .required('Le libellé de la commune est obligatoire'),

  codeCommune: Yup
    .string()
    .min(5, 'Format invalide')
    .max(5, 'Format invalide')
    .required('Le code de la commune est obligatoire'),

  codePostal: Yup
    .string()
    .min(5, 'Format invalide')
    .max(5, 'Format invalide')
    .required('Le code postal est obligatoire'),

  codePays: Yup
    .string()
    .required('Le pays est obligatoire')
    .oneOf(CodePaysINSEEKeys, 'Le pays est obligatoire'),
})

const YupSchemaInputCoordonneeBancaireDTO = Yup.object().shape({
  bic: Yup
    .string()
    .matches(/^[a-zA-Z]{6}[0-9a-zA-Z]{2}([0-9a-zA-Z]{3})?$/, 'Format non reconnu')
    .min(8).max(11)
    .required('Le BIC est obligatoire'),

  iban: Yup
    .string()
    .transform((val) => val.replace(/\s/g, ''))
    .matches(/^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([azA-Z0-9]?){0,16}$/, 'Format non reconnu')
    .min(18).max(38)
    .required('Le IBAN est obligatoire')
    .test({
      name: 'IBANCheckKey',
      message: 'IBAN invalide',
      test (value) {
        const BigInt = window.BigInt
        if (!BigInt) { return true }
        const codePays = value.substring(0, 2)
        const codeSecu = value.substring(2, 4)
        let numberstr = (value.substring(4) + codePays + codeSecu).toLowerCase()
        numberstr = numberstr
          .split('')
          .map(l => {
            if (l >= '0' && l <= '9') {
              return l
            }
            return (l.charCodeAt(0) - 97 + 10).toString()
          })
          .join('')
        const n = (BigInt(numberstr) % 97n)
        const isValid = BigInt(1) === n
        return isValid
      }
    }),

  titulaire: Yup
    .string()
    .max(100)
    .required('Le titulaire est obligatoire'),
})



const YupSchemaInputParticulier = Yup.object().shape({

  civilite: Yup
    .string()
    .nullable()
    .required('Civilité est obligatoire')
    .oneOf(['1', '2'], 'Civilité est obligatoire'),
    
  nomNaissance: Yup
    .string()
    .required('Nom est obligatoire')
    .max(80, 'Le nom ne peut pas dépasser 80 caractères')
    .matches(Nomgex, `Le nom ne doit pas comporter de chiffres, ni de caractères spéciaux à l’exception de l’apostrophe ('), du tiret (-) et de l’espace ( ). Il ne doit ni commencer ni finir par un caractère spécial.`),
    
  nomUsage: Yup
    .string()
    .notRequired()
    .max(80, 'Le nom ne peut pas dépasser 80 caractères')
    .matches(Nomgex, `Le nom ne doit pas comporter de chiffres, ni de caractères spéciaux à l’exception de l’apostrophe ('), du tiret (-) et de l’espace ( ). Il ne doit ni commencer ni finir par un caractère spécial.`),

  prenoms: Yup
    .string()
    .required('Prénoms est obligatoire')
    .max(80, 'Le prénom ne peut pas dépasser 80 caractères')
    .matches(PrenomsRegex, `Le prénom ne doit pas comporter de chiffres, ni de caractères spéciaux à l’exception de l’apostrophe ('), du tiret (-) et de l’espace ( ). Il ne doit ni commencer ni finir par un caractère spécial.
    `),
    
  dateNaissance: Yup
    .string()
    .nullable()
    .required('Date de naissance est obligatoire')
    .test({
      name: 'DateCheck',
      message: 'La date de naissance est invalide',
      test (value, params) {
        const { options } = params
        const dateNaissance = options?.context?.dateNaissance
        if (!dateNaissance) {
          return false
        }
        const date = new Date(dateNaissance)
        return !isNaN(date.getTime())
      }
    }),


  lieuNaissance: YopSchemaInputLieuNaissanceDTO,

  numeroTelephonePortable: Yup
    .string()
    .required('Numéro de téléphone est obligatoire')
    .matches(/^(0|\+33)[6-7]([0-9]{2}){4}$/, 'Format invalide : +336XXXXXXXX ou 07XXXXXXXX'),
    
  adresseMail: Yup
    .string()
    .required('Adresse mail est obligatoire')
    .email('Format invalide'),

  adressePostale: YupSchemeInputAdresseDTO,

  coordonneeBancaire: YupSchemaInputCoordonneeBancaireDTO,
})

window.YupSchemaInputParticulier = YupSchemaInputParticulier

export {
  YupSchemaInputParticulier,
  YupSchemeInputAdresseDTO,
};