<template>
  <div class="field">
    <span class="field-label">{{ label }}</span>
    <span class="field-value">{{ value }}</span>
  </div>
</template>

<script>
export default {
  name: 'Field',
  props: {
    label: {
      type: String,
      default: () => ' '
    },
    value: {
      type: null,
      default: () => ' '
    }
  }
}
</script>

<style scoped>
  .field {
    box-sizing: border-box;
    display: flex;
    margin-bottom: 8px;
    flex-direction: row;
    text-align: left;
  }
  .field-label {
    box-sizing: border-box;
    width: 30%;
    text-align: right;
    font-weight: 700;
    padding: 0 16px;
  }
  .field-value {
    box-sizing: border-box;
    padding: 0 16px;
  }
  @media only screen and (max-width: 960px) {
    .field {
      flex-direction: column;
      margin-bottom: 16px;
      position: relative;
    }
    .field-label {
      width: 100%;
      text-align: left;
      color: rgba(0, 0, 0, 0.6);
      font-size: 13px;
      height: 20px;
      line-height: 20px;
      font-weight: 400;
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 18px;
    }
    .field-value {
      color: rgba(0, 0, 0, 0.87);
      padding: 2px 2px;
      margin: 0 16px;
      margin-top: 16px;
      border-color: rgba(0, 0, 0, 0.42);
      border-style: solid;
      border-width: 0 0 thin 0;
      font-weight: 400;
      font-size: 16px;
      height: 30px;
    }
  }
</style>