<template>
  <v-container class="pa-0 pa-md-4">
    <div v-if="fini" class="alerteErrorContainer">

          <div class="alerteError">
            <v-alert
              text
              prominent
              type="success"
              class="alerteError"
            >
              Nous vous remercions {{InputParticulier.civilite === '1' ? 'Monsieur' : 'Madame'}} {{InputParticulier.nomUsage}}<br/>
              Vous recevrez un courriel de l'URSSAF quand votre inscription sera validée, en principe dans les 7 jours ouvrés.
            </v-alert>
          </div>

    </div>
    <div v-else-if="msgError !== null" class="alerteErrorContainer">

          <div class="alerteError">
            <v-alert
              text
              prominent
              type="error"
              class="alerteError"
            >
              {{ msgError }}
            </v-alert>
          </div>

    </div>
    <v-card v-else class="text-center full-height" elevation="0">
      <AvanceImmediateForm ref="avanceImmediateForm" :inputParticulier="InputParticulier"/>
          <div>
            <v-btn
              :loading="loading"
              color="primary"
              elevation="2"
              @click="onSubmit"
              style="margin-bottom: 12px;"
            >S'inscire</v-btn>
          </div>
          <!-- <div>
            <pre style="text-align: left">{{ InputParticulier }}</pre>
          </div> -->
    </v-card>
  </v-container>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import AvanceImmediateForm from "./AvanceImmediateForm.vue"



const BASE_URL = process.env.NODE_ENV === 'development' ? 
  'http://localhost:8000/api/' :
  'https://hop.leovida.com/api/'


export default {
  name: "AvanceImmediate",

  components: {
    AvanceImmediateForm,
  },

  data: function () {
    return {
    InputParticulier: {
      civilite: "",
      nomNaissance: "",
      nomUsage: "",
      prenoms: "",
      dateNaissance: "",
      lieuNaissance: {
        codePaysNaissance: "",
        departementNaissance: "",
        communeNaissance: {
          codeCommune: "",
          nomCommune: "",
        },
      },
      numeroTelephonePortable: "",
      adressePostale: {
        numeroVoie: "",
        lettreVoie: "",
        codeTypeVoie: "",
        libelleVoie: "",
        complement: "",
        lieuDit: "",
        codeCommune: "",
        codePostal: "",
        codePays: "99100"
      },
      adresseMail: "",
      coordonneeBancaire: {
        bic: "",
        iban: "",
        titulaire: "",
      },
    },
    msgError: null,
    showAllError: true,
    fini: false,
    loading: false,
    }
  },

  computed: {},

  watch: {},

  mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      const id = params.get("id");
      const key = params.get("key");
      try {
        const res = await fetch(
          BASE_URL + "public/me/UrssafData/" + id + "/" + key
        );
        if (res.ok) {
          this.InputParticulier = await res.json();
        } else {
          this.msgError = `Oups..., URL invalide`;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async onSubmit () {
      const data = this.$refs.avanceImmediateForm.getData()
      if (data !== null) {
        try {
          let uri = window.location.search.substring(1);
          let params = new URLSearchParams(uri);
          const id = params.get("id");
          const key = params.get("key");
          this.loading = true;
          const res = await fetch(BASE_URL + "public/me/UrssafData/" + id + "/" + key, {
            method: 'POST',
            headers: {
              accept: 'application/json, text/plain, */*',
              'content-type': 'application/json;charset=UTF-8',
            },
            body: JSON.stringify({
              InputParticulier: data
            })
          });
          if (res.ok) {
            this.fini = true
          } else {
            alert('Oups, une erreur est survenue veuillez réessayer')
          }
        } catch (err) {
          console.error(err);
        } finally {
          this.loading = false;
        }
      }
      console.log('submit', data)
    },
  },
};
</script>

<style lang="scss">

</style>
