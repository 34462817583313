<template>
  <v-container class="pa-0 pa-md-4" >
    <div v-if="msgError !== null" class="alerteErrorContainer">
          <div class="alerteError">
            <v-alert
              text
              prominent
              type="error"
              class="alerteError"
            >
              {{ msgError }}
            </v-alert>
          </div>
    </div>
    <div v-else >
      <v-stepper v-model="step">

        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">Préambule</v-stepper-step>
          <v-divider />

          <v-stepper-step :complete="step > 2" step="2">Mes informations & prestations</v-stepper-step>
          <v-divider />

          <v-stepper-step :complete="step > 3" step="3">Mon mode de réglement</v-stepper-step>
          <v-divider />

          <v-stepper-step :complete="step > 4" step="4">Mandat de prélèvement</v-stepper-step>
          <v-divider />

          <v-stepper-step :complete="step > 5" step="5">Signature</v-stepper-step>
          <v-divider />

          <v-stepper-step :complete="step > 6" step="6">Bienvenue</v-stepper-step>

        </v-stepper-header>

        <v-card
          v-if="loading"
          class="full-height d-flex justify-center flex-column align-center"
          elevation="0"
          height="100%"
        >
          <v-progress-circular
            :size="140"
            :width="2"
            color="#97BF10"
            indeterminate
          ></v-progress-circular>
        </v-card>


        <v-stepper-items v-show="!loading">
          <!-- Préambule -->
          <v-stepper-content step="1" class="stepper text-center">
            <Preambule @nextStep="step = 2"/>
          </v-stepper-content>

          <!-- Mes infos -->
          <v-stepper-content step="2" class="stepper text-center">
            <MesInfos @nextStep="step = 3" @prevStep="step = 1" @signalErreur="showError = true"
              :data="{
                titre,
                nom,
                prenom,
                email,
                telephone,
                adresse,
                adresseCplt,
                codePostal,
                ville,
                prestaDureeFormat,
                prestaFrequence,
                prestaTarifFormat,
                doucleClefIntervenant,
              }"
              @updateData="updateData"
            /> 
          </v-stepper-content>

          <!-- Mes prestations -->
          <!-- <v-stepper-content step="3" class="stepper">
            <MesPrestations @nextStep="step = 4" @prevStep="step = 2" @signalErreur="showError = true"
              :data="{
                prestaDureeFormat,
                prestaFrequence,
                prestaTarifFormat,
                doucleClefIntervenant,
              }"
              @updateData="updateData"
            />
          </v-stepper-content> -->

          <!-- Mode de reglemenets-->
          <v-stepper-content step="3" class="stepper">
            <ModeReglement  @nextStep="step = 4" @prevStep="step = 2" @signalErreur="showError = true"
              :data="{
                modeReglement,
                dataInputClient
              }"
              @updateData="updateData"
            
            />
            <!-- <v-card class="full-height d-flex justify-center flex-column align-center" elevation="0">
              <v-container fluid>
                <h2>Votre mode de réglement</h2>
                <v-select
                  v-model="modeReglement"
                  :items="modeReglementItems"
                  placeholder="Choisissez votre mode de réglement"
                  label=""
                ></v-select>
                <span v-if="modeReglement === 'Avance immédiate'" class="spaninfo">
                 <AvanceImmediateForm 
                    ref="avanceImmediate"
                    :inputParticulier="preDataInputClient"
                    message="Veuillez compléter ce formulaire pour que nous puissions vous inscrire à l'avance immédiate."
                 />
                </span>
              </v-container>
              <div class="buttonGroup align-self-end">
                <div class="d-flex justify-space-between">
                  <v-btn color="primary" text @click="step = 3">Précedent</v-btn>
                  <v-btn :disabled="!validPayement" class="primary" @click="goToStep5()">Suivant</v-btn>
                </div>
              </div>
            </v-card> -->
          </v-stepper-content>

          <v-stepper-content step="4" class="stepper text-center">
            <MandatPrelevement  @nextStep="step = 5" @prevStep="step = 3" @signalErreur="showError = true"
              :data="{
                id,
                key,
                modeReglement,
                RglePrenom,
                RgleNom,
                RgleIBAN,
              }"
              @updateData="updateData"
            
            />
            <!-- <v-card v-if="!loading" class="full-height d-flex justify-center flex-column align-center" elevation="0">
              <v-container fluid>
                <PrelevementAutomatiqueForm
                    ref="PrelevementAutomatique"
                    :initData="{
                      prenom: RglePrenom,
                      nom: RgleNom,
                      iban: RgleIBAN,
                    }"
                    :message="MessageMandatPrelevement[modeReglement]"
                  />  
                  <v-spacer style="margin-top:16px"/>
                  <div class="buttonGroup align-self-end">
                    <div class="d-flex justify-space-between">
                      <v-btn color="primary" text  @click="step = 4">Précedent</v-btn>
                      <v-btn color="primary" @click="signMandat">Continuer</v-btn>
                  </div>
                </div>
              </v-container>
            </v-card>
            <v-card v-else class="full-height d-flex justify-center flex-column align-center" elevation="0">
              <v-container fluid style="height:100%" class="pdf-view">
                <div>
                  <pdf :src="PDFObjectData" :page="1" :forceReRenderKey="forceReRenderKey"/>
                  <v-spacer style="border-bottom: 1px solid black"/>
                  <pdf :src="PDFObjectData" :page="2" :forceReRenderKey="forceReRenderKey"/>
                  <v-spacer style="border-bottom: 1px solid black"/>
                </div>
                <v-spacer style="margin-top:16px"/>
                <div class="buttonGroup align-self-end">
                  <div class="d-flex justify-space-between">
                    <v-btn color="primary" text  @click="step = 4">Précedent</v-btn>
                    <v-btn color="primary" @click="signPDF">Signer</v-btn>
                  </div>
                </div>
              </v-container>

            </v-card> -->
          </v-stepper-content>

          <!-- Signature -->
          <v-stepper-content step="5" class="stepper text-center">
            <v-card
                v-if="loading"
                class="full-height d-flex justify-center flex-column align-center"
                elevation="0"
                height="100%"
              >
              <v-progress-circular
                :size="140"
                :width="2"
                color="#97BF10"
                indeterminate
              ></v-progress-circular>
            </v-card>
            <v-card v-else class="full-height d-flex justify-center flex-column align-center" elevation="0">
              <v-container fluid>
                <!-- 
                  <div class="pdf"  @click="signPDF">
                    <pdf :src="PDFObjectData" :page="1" :forceReRenderKey="forceReRenderKey"/>
                    <pdf :src="PDFObjectData" :page="2" :forceReRenderKey="forceReRenderKey"/>
                  </div>
                  
                </div> -->
                <div class="pdfContent">
                  <div class="pdf">
                    <pdf :src="PDFObjectData" :page="1" :forceReRenderKey="forceReRenderKey"/>
                  </div>
                  <div class="pdf">
                    <pdf :src="PDFObjectData" :page="2" :forceReRenderKey="forceReRenderKey"/>
                  </div>
                </div>
             
               
              </v-container>
                <v-spacer style="margin-top:16px"/>
                <div class="buttonGroup align-self-end">
                  <div class="d-flex justify-space-between">
                    <v-btn color="primary" text  @click="step = 4">Précedent</v-btn>
                    <v-btn color="primary" @click="signPDF">Signer</v-btn>
                  </div>
                </div>
                <!-- <object :data="PDFObjectData" type="application/pdf" width="100%" height="100%" ></object> -->
              

            </v-card>
          </v-stepper-content>

          <v-stepper-content step="6" class="stepper">
            <v-card
                v-if="loading"
                class="full-height d-flex justify-center flex-column align-center"
                elevation="0"
                height="100%"
              >
              <v-progress-circular
                :size="140"
                :width="2"
                color="#97BF10"
                indeterminate
              ></v-progress-circular>
            </v-card>
            <v-card v-else class="full-height d-flex justify-center flex-column align-center" elevation="0">
              <v-alert
                text
                prominent
                type="success"
                class="alerteError"
              >
                Un exemplaire de ce contrat vous a été adressé par mail.
              </v-alert>
              <v-alert
                v-if="modeReglement === 'Avance immédiate'"
                text
                prominent
                type="success"
                class="alerteError"
              >
                Vous recevrez un courriel de l'URSSAF quand votre inscription à l'avance immédiate sera validée, en principe dans les 7 jours ouvrés.
              </v-alert>
              <v-container fluid>
                <div class="pdfContent">
                  <div class="pdf">
                    <pdf :src="PDFObjectData" :page="1" :forceReRenderKey="forceReRenderKey"/>
                  </div>
                  <div class="pdf">
                    <pdf :src="PDFObjectData" :page="2" :forceReRenderKey="forceReRenderKey"/>
                  </div>
                </div>
              </v-container>
            </v-card>
          </v-stepper-content>

        </v-stepper-items>
      </v-stepper>
    </div>
    

    <!-- Signature -->
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title class="headline lighten-2">
          Signature
        </v-card-title>
        <v-card-text>
          
          <div class="tabsSign">
            <canvas-sign
              :valid.sync="hasSignature"
              @change="onDataURIChange"
            />
          </div>
          <v-text-field v-model="signataire" :rules="[v => !!v || '']" label="Nom complet" required/>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="gray"
            class="ma-1"
            text
            @click="dialog = false"
          >
            Annuler
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="!signataire || !hasSignature"
            text
            @click="sign()"
          >
            Signer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showError"
      max-width="800"
    >
      <v-card>
        <v-card-title class="headline lighten-2">
          Signaler une erreur
        </v-card-title>
        <v-card-text>
          <v-textarea v-model="errorMessage" label="" placeholder="Votre message"/>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            :disabled="!errorMessage"
            text
            @click="onPressError()"
          >
            Envoyer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

   

  </v-container>
</template>

<script>
  /* eslint-disable vue/no-unused-components */
import CanvasSign from './CanvasSign/canvas'
import Field from './Field'
import pdf from 'vue-pdf'
import AvanceImmediateForm from './AvanceImmediateForm'
import PrelevementAutomatiqueForm from './PrelevementAutomatiqueForm'


import { IbanCheck } from '../Utils'

import Preambule from './Stepper/Preambule.vue'
import MesInfos from './Stepper/MesInfos.vue'
import ModeReglement from './Stepper/ModeReglement.vue'
import MandatPrelevement from './Stepper/MandatPrelevement.vue'

const MessageMandatPrelevement = {
  'Prélèvement automatique': ` Prélèvement automatique `,
  'Avance immédiate': ` Avance immédiate `,
  'CESU': ` CESU `,
}

const BASE_URL = process.env.NODE_ENV === 'development' ? 
  'http://localhost:8000/api/' :
  'https://hop.leovida.com/api/'

export default {
  name: 'Signature',

  components: {
    CanvasSign,
    pdf,
    Field,
    AvanceImmediateForm,
    PrelevementAutomatiqueForm,
    Preambule,
    MesInfos,
    ModeReglement,
    MandatPrelevement,
  },

  data: () => ({
    id: null,
    key: null,
    loading: true,
    msgError: null,
    // Stepper
    step: 1,
    //
    titre: '',
    nom: '', 
    prenom: '',
    telephone: '',
    email: '',
    //
    adresse: '',
    codePostal: '',
    ville: '',
    adresseCplt: '',
    //
    presenceClient: false,
    doucleClefIntervenant: undefined,
    //
    prestaDuree: 0,
    prestaFrequence: '', // 1, // 1 Semaine - 2 Quinzaine
    prestaTarif: 0,
    //
    modeReglement: 'Avance immédiate', // 'Avance immédiate', // 'Prélèvement automatique',
    // Prélèvement automatique, CESU, Virement bancaire
    modeReglementItems: ['Prélèvement automatique', 'CESU', 'Avance immédiate'],
    MessageMandatPrelevement: MessageMandatPrelevement,
    // IBAM
    RgleNom: '',
    RglePrenom: '',
    RgleIBAN: '',
    PDFObjectData: '',
    //
    preDataInputClient: {},
    dataInputClient: null,
    prelevementAutomatiqueData: {},
    //
    dialog: false,
    enableDraw: true,
    text: '',

    dataURIMandat: null,
    signataireMandat: '',

    dataURI: null,
    signataire: '',
    hasSignature: false,
    //
    showError: false,
    errorMessage: '',
    //
    forceReRenderKey: 0,
    // validPayement: true,
    // autoStyleHeight: {
    //   height: 'calc(100vh - 188px)',
    //   backgroundColor: 'red'
    // }
  }),


  props: {
    // data: {
    //   type: Object,
    //   required: true,
    // },
  },

  computed: {
    validPayement () {
      return this.modeReglement
    },
    formatData () {
      return {
        fullName: `${this.titre} ${this.nom} ${this.prenom}`
      }
    },
    prestaDureeFormat () {
      const h = String(Math.floor(this.prestaDuree / 60))
      const m = String(this.prestaDuree % 60)
      return h + 'h' + m.padStart(2, '0')
    },
    prestaTarifFormat () {
      return `${this.prestaTarif.toFixed(2)}€ TTC (TVA 10%)`
    }
  },

  watch: {
    step () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      if (this.step === 5) {
        this.getPreviewPDF()
      }
    }
  },

  mounted () {
    this.fetchDocSign()
    this.autoHeight()
    window.addEventListener('resize', () => {
      this.autoHeight ()
    });
  },

  methods: {
    autoHeight () {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },

    async fetchDocSign() {
      let uri = window.location.search.substring(1)
      let params = new URLSearchParams(uri)
      const id = params.get('id')
      const key = params.get('key')
      if (!id || !key) {
        return this.showMessageError({ message: `Oups..., URL invalide`})
      }
      try {
        const res = await fetch(BASE_URL + 'Sign/get/' + id + '/' + key)
        const data = await res.json()
        if (res.ok === false) {
          return this.showMessageError(data?.error)
        }
        this.id = id
        this.key = key
        this.titre = data.titre
        this.nom = data.nom
        this.prenom = data.prenom
        this.telephone = data.telephone
        this.email = data.email
        //
        this.adresse = data.adresse
        this.codePostal = data.codePostal
        this.ville = data.ville
        this.adresseCplt = data.adresseCplt
        //
        this.prestaDuree = data.prestaDuree
        this.prestaFrequence = data.prestaFrequence
        this.prestaTarif = data.prestaTarif
        this.loading = false
        this.dataInputClient = {
          civilite: this.titre === 'Monsieur' ? '1' : ((this.titre === 'Madame' || this.titre === 'Mademoiselle') ? '2' : ''),
          numeroTelephonePortable: /^(\+33|0)(6|7)/.test(this.telephone) ? this.telephone : '',
          adresseMail: this.email ?? '',
        }
      } catch (e) {
        return this.showMessageError(e)
      }
      // this.getPreviewPDF()
      // this.loading = false
    },
    async getPreviewPDF () {
      try {
        this.loading = true
        const res = await fetch(BASE_URL + "Sign/preview/", {
          method: 'POST',
          headers: {
            accept: 'application/json, text/plain, */*',
            'content-type': 'application/json;charset=UTF-8',
          },
          body: JSON.stringify({
            id: this.id,
            key: this.key,
            clef: this.doucleClefIntervenant,
            mode: this.modeReglement,
            presenceClient: this.presenceClient,
            doucleClefIntervenant: this.doucleClefIntervenant,
            IBAN: {
              nom: this.RgleNom,
              prenom: this.RglePrenom,
              IBAN: this.RgleIBAN
            },
            inputParticulier: this.dataInputClient
          })
        });
        const pdfBlob = await res.blob()
        const arrayBuffer = await pdfBlob.arrayBuffer()
        const buffer = new Uint8Array(arrayBuffer)
        // window.ddd = buffer
        // console.log(buffer)
        this.PDFObjectData = buffer
        this.forceReRenderKey++
        this.loading = false
      } catch (e) {
        this.showMessageError(e)
      }
    },
    async sign () {
      this.dialog = false
      try {
        this.loading = true
        const res = await fetch(BASE_URL + 'Sign/sign/', {
          method: 'POST',
          headers: {
            accept: 'application/json, text/plain, */*',
            'content-type': 'application/json;charset=UTF-8',
          },
          body: JSON.stringify({
            id: this.id,
            key: this.key,
            clef: this.doucleClefIntervenant,
            mode: this.modeReglement,
            presenceClient: this.presenceClient,
            doucleClefIntervenant: this.doucleClefIntervenant,
            IBAN: {
              nom: this.RgleNom,
              prenom: this.RglePrenom,
              IBAN: this.RgleIBAN
            },
            signataireMandat: this.signataireMandat,
            signDataMandat: this.dataURIMandat,
            signataire: this.signataire,
            signData: this.dataURI,
            inputParticulier: this.dataInputClient
          })
        })
        if (res.ok === false) {
          const data = await res.json()
          return this.showMessageError(data?.error)
        }
        const pdfContent = await res.blob()
        const url = URL.createObjectURL(pdfContent)
        this.PDFObjectData = url
        this.loading = false
        this.step += 1
        
      } catch (e) {
        this.showMessageError(e)
      }
    },
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    signPDF () {
      this.dialog = true
    },
    onDataURIChange (dataURI) {
      this.dataURI = dataURI
    },
    showMessageError (e) {
      this.msgError = e?.message || 'Une erreur est survenue.'
    },
    checkIBAN () {
      return IbanCheck(this.RgleIBAN)
    },
    async onPressError () {
      try {
        this.loading = true
        await fetch(BASE_URL + 'Sign/error/', {
          method: 'POST',
          headers: {
            accept: 'application/json, text/plain, */*',
            'content-type': 'application/json;charset=UTF-8',
          },
          body: JSON.stringify({
            id: this.id,
            key: this.key,
            message: this.errorMessage
          })
        })
        this.showError = false
      } catch (e) {
        this.showMessageError(e)
      }
    },
    goToStep5() {
      if (this.modeReglement === 'Avance immédiate') {
        if (!this.$refs.avanceImmediate) {
          this.showMessageError()
        }
        const data = this.$refs.avanceImmediate.getData()
        if (data !== null) {
          this.dataInputClient = data
          console.log('dataInputClient', this.dataInputClient)
          this.step = 5
        }
      // } else if (this.modeReglement === 'Prélèvement automatique') {
      //   if (!this.$refs.PrelevementAutomatique) {
      //     this.showMessageError()
      //   }
      //   const data = this.$refs.PrelevementAutomatique.getData()
      //   if (data !== null) {
      //     this.RgleNom = data.nom
      //     this.RglePrenom = data.prenom
      //     this.RgleIBAN = data.iban
      //     this.step = 5
      //   }
      } else {
        this.step = 5
      }
    },
    signMandat() {
      if (!this.$refs.PrelevementAutomatique) {
        this.showMessageError()
      }
      const data = this.$refs.PrelevementAutomatique.getData()
      if (data !== null) {
        console.log(data)
        // this.RgleNom = data.nom
        // this.RglePrenom = data.prenom
        // this.RgleIBAN = data.iban
        this.step = 5
        console.log({
          nom: this.RgleNom,
          prenom: this.RglePrenom,
          IBAN: this.RgleIBAN
        })
      }
      
    },
    // ===== NOUVELLE
    updateData (data) {
      if (data.modeReglement === 'Avance immédiate' && data.dataInputClient) {
        this.RgleIBAN = data.dataInputClient?.coordonneeBancaire?.iban
      }
      for (const key in data) {
        this.$set(this, key, data[key])
      }
    }
  }
}
</script>

<style lang="scss">
  .alerteErrorContainer {
    margin: 16px;
  }
  .alerteError {
    background-color: white;
    text-align: center;
    width: auto;
  }

  .imgLogo {
    max-height: 120px;
    max-width: 100%;
  }

  .pdf-view {
    margin: 0 -24px;
    padding: 0;
  }
  
  // Header         =   64    56
  // Step header    =   72    72
  // Step padding   =   16    00
  // paddinf ine    =   24    08
  //  TOTAL         =  216   128
  .stepper {
    // height: calc(100vh - 168px);
    // height: calc(var(--vh, 1vh) * 100 - 168px);
    overflow-y: auto;
  }
  @media only screen and (max-width: 960px) {
    .v-stepper {
      border-radius: 0 !important;
      box-shadow: none !important;
    }
    .stepper {
      // height: calc(100vh - 128px);
      // height: calc(var(--vh, 1vh) * 100 - 128px);
      padding: 8px 16px !important;
      overflow-y: auto;
      
    }
  }

  .v-stepper__wrapper {
    height: 100%;
  }

  .full-height {
    height: 100%;
    min-height: calc(var(--vh, 1vh) * 100 - 216px);
    display: flex !important;
    flex-direction: column;
    justify-items: flex-start;
    overflow-y: auto;
  }
  @media only screen and (max-width: 960px) {
    .full-height {
      height: 100%;
      min-height: calc(var(--vh, 1vh) * 100 - 144px);
    }
  }

  .buttonGroup {
    width: 100%;
    margin-top: auto;
    justify-self: flex-end;
  }

  .spaninfo {
    display: block;
    color: #8c8c8c;
    text-align: center;
    font-size: 14px;
  }
  .hRIB {
    color: #00796b;
    padding-left: 6px;
    padding-top: 6px;
  }


  .pdfContent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    
  }
  .pdf {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    flex-basis: 830px;
  }
</style>
