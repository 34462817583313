<template>
  <v-dialog
      :value="visible"
      persistent
      max-width="800"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title class="headline lighten-2">
          Signature
        </v-card-title>
        <v-card-text>
          
          <div class="tabsSign">
            <canvas-sign
              :valid.sync="hasSignature"
              @change="onDataURIChange"
            />
          </div>
          <v-text-field v-model="signataire" :rules="[v => !!v || '']" label="Nom complet" required/>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="gray"
            class="ma-1"
            text
            @click="close"
          >
            Annuler
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="!signataire || !hasSignature"
            text
            @click="sign()"
          >
            Signer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import CanvasSign from './canvas'

export default {
  components: {
    CanvasSign,
  },
  data: () => ({
    enableDraw: true,
    dataURI: null,
    signataire: '',
    hasSignature: false,
  }),

  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    close () {
      this.$emit('update:visible', false)
    },
    onDataURIChange (dataURI) {
      this.dataURI = dataURI
    },
    sign () {
      this.$emit('onSign', {
        signataire: this.signataire,
        dataURI: this.dataURI,
      })
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style>

</style>