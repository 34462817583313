<template>
  <div>
    <SignDialog
      v-if="showDialogSign"
      :visible.sync="showDialogSign"
      @onSign="handleSign"
    />

    <v-card v-if="localState === 'SAISIE_INFO'" class="full-height d-flex justify-center flex-column align-center" elevation="0">
      <v-container fluid>
        <p class="text-left" v-if="localData.modeReglement === 'CESU'">
          <v-alert
          text
            :icon="false"
            type="info"
            class="text-left"
          >
          Dans l'hypothèse où vous n'auriez pas réglé dans les 30 jours votre facture à l'aide de titres CESU ou e-CESU, il sera nécessaire de permettre à Leovida de prélever le montant de votre facture ou du solde restant dû. A cette fin, nous vous demandons d'indiquer votre IBAN.
          </v-alert>
          
        </p>
        <p class="text-left" v-else-if="localData.modeReglement === 'Prélèvement automatique'">
          <v-alert
            text
            :icon="false"
            type="info"
            class="text-left"
          >
            Vous avez choisi le prélèvement automatique comme mode de règlement. Les prélèvements sont généralement réalisés entre le 10 et le 13 du mois suivant le mois facturé. A cette fin, nous vous demandons d'indiquer votre IBAN
          </v-alert>
         
        </p>
        <p class="text-left" v-else-if="localData.modeReglement === 'Avance immédiate'">
          <v-alert
            text
            :icon="false"
            type="info"
            class="text-left"
          >
           <p>
            Vous avez choisi l'Avance immédiate comme mode de règlement. C'est donc l'URSSAF qui règlera à Leovida la totalité de votre facture.<br/>
          </p>
            Cependant, l'URSSAF peut refuser de régler votre facture si :
            <ul class="info-list">
              <li>Vous ne vous êtes pas connecté(e) à votre espace <a href="https://www.particulier.urssaf.fr/#/identification" target="_blank">particulier.urssaf.fr</a></li>
              <li>Une prestation de la facture a été réalisée avant que vous vous connectiez à votre espace pour la première fois</li>
              <li>Les URSSAF ne sont pas parvenu à prélever 50% de votre facture sur votre compte bancaire</li>
            </ul>
            <p>
              Aussi, en cas de refus de l'URSSAF, il est nécessaire de permettre à Leovida de prélever le montant de votre facture. A cette fin, nous vous demandons d'indiquer votre IBAN.
            </p>
          </v-alert>
        </p>
        <PrelevementAutomatiqueForm ref="prelevementAutomatique" :initData="{
          prenom: localData.RglePrenom,
          nom: localData.RgleNom,
          iban: localData.RgleIBAN,
        }" />
      </v-container>
      <div class="buttonGroup align-self-end">
      
        <div class="d-flex justify-space-between">
          <v-btn color="primary" text @click="prevStep">Précedent</v-btn>
            <v-btn color="primary" @click="nextLocalState">Continuer</v-btn>
        </div>
      </div>
    </v-card>

    <v-card v-else class="full-height d-flex justify-center flex-column align-center" elevation="0">
      <v-container fluid>
        <div class="pdfContent">
          <div class="pdf">
            <pdf :src="PDFObjectData" :page="1" :forceReRenderKey="forceReRenderKey" />
          </div>
          
        </div>

      </v-container>
      <div class="buttonGroup align-self-end">
        <div class="d-flex justify-space-between">
          <v-btn color="primary" text @click="prevLocalState">Précedent</v-btn>
          <v-btn color="primary" @click="signPDF">Signer</v-btn>
        </div>
      </div>
    </v-card>
    
   
        <!-- <v-card   class="full-height d-flex justify-center flex-column align-center" elevation="0">
  
      <v-container fluid>
        <PrelevementAutomatiqueForm ref="prelevementAutomatique" :initData="{
          prenom: localData.RglePrenom,
          nom: localData.RgleNom,
          iban: localData.RgleIBAN,
        }" :message="MessageMandatPrelevement[localData.modeReglement]" />
        <v-spacer style="margin-top:16px" />
        <div class="buttonGroup align-self-end">
          <div class="d-flex justify-space-between">
            <v-btn color="primary" text @click="prevStep">Précedent</v-btn>
            <v-btn color="primary" @click="nextLocalState">Continuer</v-btn>
          </div>
        </div>
      </v-container>
      
    </v-card>

   
  
  <v-card v-else class="full-height d-flex justify-center flex-column align-center" elevation="0">
    <v-container fluid style="height:100%" class="pdf-view">
      <div>
        <pdf :src="PDFObjectData" :page="1" :forceReRenderKey="forceReRenderKey" />
        <v-spacer style="border-bottom: 1px solid black" />
        <pdf :src="PDFObjectData" :page="2" :forceReRenderKey="forceReRenderKey" />
        <v-spacer style="border-bottom: 1px solid black" />
      </div>
      <v-spacer style="margin-top:16px" />
      <div class="buttonGroup align-self-end">
        <div class="d-flex justify-space-between">
          <v-btn color="primary" text @click="prevLocalState">Précedent</v-btn>
          <v-btn color="primary" @click="signPDF">Signer</v-btn>
        </div>
      </div>

    </v-container>

  </v-card> -->
</div>

  
</template>

<script>
/* eslint-disable vue/no-unused-components */
import API from '../API'
import PrelevementAutomatiqueForm from './../PrelevementAutomatiqueForm'
import SignDialog from '../CanvasSign/SignDialog'
import pdf from 'vue-pdf'

const MessageMandatPrelevement = {
  'Prélèvement automatique': ` Prélèvement automatique `,
  'Avance immédiate': ` Avance immédiate `,
  'CESU': ` CESU `,
}

export default {

  components: {
    PrelevementAutomatiqueForm,
    pdf,
    SignDialog,
  },

  data: () => ({
    localData: {},
    MessageMandatPrelevement,
    localState: 'SAISIE_INFO',
    forceReRenderKey: 0,
    PDFObjectData: '',
    
    //
    showDialogSign: false,
    enableDraw: true,
    text: '',
    dataURI: null,
    signataire: '',
    hasSignature: false,
  }),

  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  watch: {
    data() {
      this.localData = this.data ?? {}
    },
  },

  mounted() {
    this.localData = this.data ?? {}
  },

  methods: {
    prevLocalState () {
      this.localState =  'SAISIE_INFO'
    },
    nextLocalState () {
      const data = this.$refs.prelevementAutomatique.getData()
      if (data !== null) {
        console.log('DATA', data)
        this.localData = {
          ...this.localData,
          RgleNom: data.nom,
          RglePrenom: data.prenom,
          RgleIBAN: data.iban
        }
        this.previewMandat()
      } 
      // this.localState = 'SIGN'
    },
    async previewMandat () {
      this.loading = true
      try {
        const res = await API.previewSignMandat({
          id: this.localData.id,
          key: this.localData.key,
          IBAN: {
            nom: this.localData.RgleNom,
            prenom: this.localData.RglePrenom,
            IBAN: this.localData.RgleIBAN
          },
        })
        window.res = res
        if (res.ok === false) {
          return
        }
        const pdfBlob = await res.blob()
        const arrayBuffer = await pdfBlob.arrayBuffer()
        const buffer = new Uint8Array(arrayBuffer)
        this.PDFObjectData = buffer
        this.forceReRenderKey++
        this.loading = false
        this.localState = 'SIGN'
      } finally {
        this.loading = false
      }
    },
    prevStep() {
      this.$emit("prevStep")
    },
    signalErreur() {
      this.$emit("signalErreur")
    },
    signPDF () {
      this.showDialogSign = true
    },
    onDataURIChange (dataURI) {
      this.dataURI = dataURI
    },
    handleSign ({ signataire, dataURI }) {
      this.localData.signataireMandat = signataire
      this.localData.dataURIMandat = dataURI
      this.$emit("updateData", this.localData)
      this.$emit("update:visible", false)
      this.$emit("nextStep")

    },
  },
}
</script>

<style lang="scss" scoped>


.v-alert {
  a {
    color: #2196f3;
    font-weight: 700;
  }
}


.info-list {
  font-size: 0.9em;
  margin-left: 16px;
  margin-bottom: 1em;
}
</style>