<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="4"
        >
        <v-btn
          class="mb-4"
          :color="!enableDraw ? '' : 'primary'"
          block
          @click="enableDraw = true"
        >
          Dessiner
        </v-btn>
        <v-btn
          class="pb-1"
          :color="enableDraw ? '' : 'primary'"
          block
          @click="enableDraw = false"
        >
          Saisir
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        sm="8"
        >
        <div style="display: flex; justify-content: center;     flex-wrap: wrap">
          <v-text-field v-if="!enableDraw" v-model.trim="text" label="Signature" style="max-width: 420px;" />
          <canvas
            ref="canvas"
            :height="height"
            :width="width"
            :class="enableDraw ? 'draw' : 'noDraw'"
            style="border: 1px solid rgba(204, 204, 204); background-color: rgba(244, 244, 244); max-width: 420px; width: 100%"
            @mousedown="handleDrawStart"
            @mousemove="handleDrawMove"
            @mouseup="handleDrawEnd"
            @touchstart="handleDrawStart"
            @touchmove="handleDrawMove"
            @touchend="handleDrawEnd"
            @touchcancel="handleDrawEnd"
          ></canvas>
          <div>
            <v-btn v-if="enableDraw" color="red darken-1" text block @click="clear">Effacer</v-btn>
          </div>
        </div>
      </v-col>
      
    </v-row>
  </div>
</template>

<script>
// @mouseleave="handleDrawEnd"
export default {
  props: {
    height: {
      type: Number,
      default: 120
    },
    width: {
      type: Number,
      default: 420
    },
    valid: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    ctx: null,
    lastPoint: null,
    enableDraw: true,
    draw: false,
    text: '',
  }),


  computed: {
    hasSignature () {
      return (this.enableDraw && this.draw) || (!this.enableDraw && this.text !== '')
    }
  },

  watch: {
    enableDraw (){
      if (!this.enableDraw) {
        this.drawText()
      } else {
        this.clear()
      }
    },
    text () {
      if (!this.enableDraw) {
        this.drawText()
      }
    },
    hasSignature () {
      this.$emit('update:valid', this.hasSignature)
    }
  },

  mounted () {
    this.ctx = this.$refs['canvas'].getContext('2d')
    this.ctx.strokeStyle = '#000000'
    this.ctx.lineWidth = 1
    this.clear()
  },

  methods:{
    getTouchPos (touchEvent) {
      const rect = this.$refs['canvas'].getBoundingClientRect()
      const data = {
        x: (touchEvent.touches[0].clientX - rect.left) * (this.width / rect.width) ,
        y: (touchEvent.touches[0].clientY - rect.top) * (this.height / rect.height),
      }
      return data
    },
    getPos ($event) {
      if ($event.touches) {
        return this.getTouchPos($event)
      }
      return {
        x: $event.offsetX,
        y: $event.offsetY
      }
    },
    handleDrawStart ($event) {
      console.log($event)
      $event.preventDefault()
      if (this.enableDraw) {
        this.lastPoint = this.getPos($event)
      }
    },
    handleDrawMove ($event) {
      $event.preventDefault()
      if (this.lastPoint) {
        const newPoint = this.getPos($event)
        this.ctx.beginPath()
        this.ctx.moveTo(this.lastPoint.x, this.lastPoint.y)
        this.ctx.lineTo(newPoint.x, newPoint.y)
        this.ctx.stroke()
        this.lastPoint = newPoint
      }
    },
    handleDrawEnd ($event) {
      $event.preventDefault()
      this.lastPoint = null
      this.draw = true
      this.emitChange()
    },
    clear () {
      // this.ctx.fillStyle = 'rgba(255, 255, 255, 0)'
      this.ctx.clearRect(0, 0, this.width, this.height)
      this.draw = false
      this.emitChange()
    },
    drawText () {
      this.clear()
      this.ctx.fillStyle = '#000000'
      this.ctx.font = "45px Fair Prosper";
      this.ctx.textBaseline = "middle"
      this.ctx.textAlign = "center"
      this.ctx.fillText(this.text, this.width / 2, this.height / 2, this.width - 10);
      this.emitChange()
    },
    emitChange () {
      this.$emit('change', this.$refs['canvas'].toDataURL('image/jpg'))
    }
  }
}
</script>

<style lang="scss" scoped>
  .draw {
    cursor: crosshair;
  }
  .noDraw {
    cursor: no-drop;
  }
  .canvasWrap {
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
  }
  .canvas-buttons {
    width: 200px;
    display: block;
  }
  .canvas-canvas {
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 960px) {
  }
</style>