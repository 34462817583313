<template>
  <v-card class="full-height d-flex justify-center flex-column align-center" elevation="0">
    <v-container fluid>
      <h2>Votre mode de réglement</h2>
      <v-select
        v-model="localData.modeReglement"
        :items="modeReglementItems"
        placeholder="Choisissez votre mode de réglement"
        label=""
      ></v-select>
      <span v-if="localData.modeReglement === 'Avance immédiate'" class="spaninfo">
        <AvanceImmediateForm 
          ref="avanceImmediate"
          :inputParticulier="data.dataInputClient"
          message="Veuillez compléter ce formulaire pour que nous puissions vous inscrire à l'avance immédiate."
        />
      </span>
    </v-container>

      <div class="buttonGroup align-self-end text-center">
        <div class="d-flex justify-space-between">
          <v-btn color="primary" text @click="prevStep">Précedent</v-btn>
          <v-btn color="primary" @click="nextStep">Suivant</v-btn>
        </div>
      </div>
  </v-card>
</template>

<script>
import ModeReglements from '../../data/ModeReglements'
import AvanceImmediateForm from './../AvanceImmediateForm'

export default {

  components: {
    AvanceImmediateForm,
  },

  data: () => ({
    localData: {},
    modeReglementItems: ModeReglements
  }),

  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  watch: {
    data () {
      this.localData = this.data ?? {}
    },
  },

  mounted () {
    this.localData = this.data ?? {}
  },

  methods: {
    nextStep() {
      if (this.localData.modeReglement === 'Avance immédiate') {
        const data = this.$refs.avanceImmediate.getData()
        if (data !== null) {
          this.localData.dataInputClient = data
        } else {
          return
        }
      }
      this.updateData()
      this.$emit("nextStep")
    },
    prevStep() {
      this.$emit("prevStep")
    },
    signalErreur() {
      this.$emit("signalErreur")
    },
    updateData () {
      this.$emit("updateData", {
        modeReglement: this.data.modeReglement,
        dataInputClient: this.data.dataInputClient
      })
    }
  },
}
</script>

<style></style>