<template>
  <v-card class="full-height d-flex justify-center flex-column align-center" elevation="0">
      <v-container fluid>
        <fieldset>
          <legend>Mes informations</legend>
          <v-row dense>
            <v-col sm="12" cols="12">
              <v-text-field
                label="Titre"
                :value="data.titre"
                readonly
                filled
                hide-details
              />
            </v-col>
            <v-col sm="12" cols="12">
              <v-text-field
                label="Nom"
                :value="data.nom"
                readonly
                filled
                hide-details
              />
            </v-col>
            <v-col sm="12" cols="12">
              <v-text-field
                label="Prénom"
                :value="data.prenom"
                readonly
                filled
                hide-details
              />
            </v-col>
            <v-col sm="12" cols="12">
              <v-text-field
                label="Email"
                :value="data.email"
                readonly
                filled
                hide-details
              />
            </v-col>
            <v-col sm="12" cols="12">
              <v-text-field
                label="Téléphone"
                :value="data.telephone"
                readonly
                filled
                hide-details
              />
            </v-col>
            <v-spacer />
            <v-col sm="12" cols="12">
              <v-text-field
                label="Adresse"
                :value="data.adresse"
                readonly
                filled
                hide-details
              />
            </v-col>
            <v-col sm="12" cols="12">
              <v-text-field
                label="Adresse Cplt"
                :value="data.adresseCplt"
                readonly
                filled
                hide-details
              />
            </v-col>
            <v-col sm="12" cols="12">
              <v-text-field
                label="Code postal - Ville"
                :value="data.codePostal"
                readonly
                filled
                hide-details
              />
            </v-col>
          </v-row>
        </fieldset>
        <fieldset>
          <legend>Mes prestations</legend>
          <v-row dense>
            <v-col sm="12" cols="12">
              <v-text-field
                label="Durée"
                :value="localData.prestaDureeFormat"
                readonly
                filled
                hide-details
              />
            </v-col>
            <v-col sm="12" cols="12">
              <v-text-field
                label="Fréquence"
                :value="localData.prestaFrequence"
                readonly
                filled
                hide-details
              />
            </v-col>
            <v-col sm="12" cols="12">
              <v-text-field
                label="Tarif horaire"
                :value="localData.prestaTarifFormat"
                readonly
                filled
                hide-details
              />
            </v-col>
          </v-row>
        </fieldset>
        <v-col cols="12">
          <span class="text-left" :class="{'red--text' : showError}">Remise d'un double des clefs de votre domicile :</span>
        </v-col>
        <v-col cols="12">
          <v-radio-group v-model="data.doucleClefIntervenant" column style="margin-top: 0px" :error="showError">
            <v-radio label="Je remets un exemplaire des clefs lors de la première prestation de l'intervenant" :value="true"></v-radio>
            <v-radio label="Je ne confie pas mes clefs à l'intervenant ()" :value="false">
              <template v-slot:label>
                <div>Je ne confie pas mes clefs à l'intervenant, mais je m'engage à toujours lui permettre l'accès à mon domicile lors de ses prestations<br/>
                  <small>(j'ai connaissance qu'à défaut Leovida pourra facturer ladite prestation)</small>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
        
        <!-- <Field :value="data.titre" label="Titre" />
        <Field :value="data.nom" label="Nom" />
        <Field :value="data.prenom" label="Prénom" />
        <Field :value="data.email" label="Email" />
        <Field :value="data.telephone" label="Téléphone" />
        <v-spacer />
        <Field :value="data.adresse" label="Adresse" />
        <Field :value="data.adresseCplt" label="Adresse Cplt" />
        <Field :value="data.codePostal + ' ' + data.ville" label="Code postal - Ville" /> -->
        
      </v-container>
      
      <div class="buttonGroup align-self-end">
        <v-btn color="error" class="ma-1"  plain @click="signalErreur">Une erreur ?</v-btn>
        <div class="d-flex justify-space-between">
          <v-btn color="primary" text @click="prevStep">Précedent</v-btn>
          <v-btn color="primary" @click="nextStep">Suivant</v-btn>
        </div>
      </div>
    </v-card>
</template>

<script>
export default {

  components: {

  },

  data: () => ({
    localData: {},
    showError: false,
  }),

  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  watch: {
    data () {
      this.localData = this.data ?? {}
    },
  },

  mounted () {
    this.localData = this.data ?? {}
  },

  methods: {
    nextStep() {
      if (this.localData.doucleClefIntervenant === undefined) {
        this.showError = true
        return
      }
      this.showError = false
      this.updateData()
      this.$emit("nextStep")
    },
    prevStep() {
      this.$emit("prevStep")
    },
    signalErreur() {
      this.$emit("signalErreur")
    },
    updateData () {
      this.$emit("updateData", {
        doucleClefIntervenant: this.data.doucleClefIntervenant
      })
    }
  },
}
</script>

<style lang="scss" scoped>
  legend {
    margin-bottom: 0;
  }
</style>