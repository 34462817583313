<template>
  <div>
    <p class="text-left">
      {{ message }}
    </p>
    <v-form ref="form">
      <fieldset>
        <legend>Coordonnée bancaire</legend>
        <FormBuilder
          v-model="data"
          :schema="InputParticulierForm3"
          :yupSchema="YupSchemaInputParticulier"
          @inputItem="onChangeInput"
          :showAllError="showAllError"
        />
      </fieldset>
    </v-form>

  </div>


</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import set from "lodash/set";
import get from "lodash/get";
import { YupSchemaInputParticulier } from "../Schema/InputSchema";

import FormBuilder from "./FormBuilder";


const TesteYup = (yup, key, value, context) => {
  try {
    const val = set({}, key, value);
    yup.validateSyncAt(key, val, { abortEarly: true, context: context?.$data?.InputParticulier});
    return true;
  } catch (e) {
    return e.message;
  }
};
const YupRules = function (context, yup, key) {
  return {
    rules: [(value) => TesteYup(yup, key, value, context)],
  };
};

// ==== CoordonneeBancaire
const InputParticulierForm3 = (context) => [
  {
    label: "Nom",
    name: "nom",
    size: 6,
    ...YupRules(context, YupSchemaInputParticulier, 'nomNaissance')
  },
  {
    label: "Prénom",
    name: "prenom",
    size: 6,
    ...YupRules(context, YupSchemaInputParticulier, 'prenoms')
  },
  {
    label: "IBAN",
    name: "iban",
    ...YupRules(context, YupSchemaInputParticulier, "coordonneeBancaire.iban"),
  },
];

const DEFAULT_FORM_VALUE = {
  nom: '',
  prenom: '',
  iban: '',
}

export default {
  name: "PrelevementAutomatiqueForm",

  components: {
    FormBuilder,
  },

  data: function () {
    return {
      InputParticulierForm3: InputParticulierForm3(this),
      YupSchemaInputParticulier: YupSchemaInputParticulier,
      msgError: null,
      showAllError: false,
      fini: false,
      isCheck: false,
      data: DEFAULT_FORM_VALUE
    }
  },

  props: {
    initData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    message: {
      type: String,
      required: false,
      default: () => ''
    }
  },

  computed: {
  },

  watch: {
    initData () {
      this.data = {
        ...DEFAULT_FORM_VALUE,
        ...this.initData,
      }
    }
  },

  mounted() {
    this.data = {
      ...DEFAULT_FORM_VALUE,
      ...this.initData,
    }
  },

  methods: {
    get(object, key) {
      const val = get(object, key);
      return val;
    },
    inputChange(event, key) {
      this.touched[key] = true;
    },
    onChangeInput(key, value, ...args) {
      console.log(key, value)
      this.$set(this.data, key, value)
      this.$emit('update:' + key, value)
    },
    getData () {
      const isValid = this.$refs.form.validate();
      this.isCheck = true
      console.log('getData', this.data)
      if (isValid) {
        return this.data
      } else {
        return null
      }
    }
  },
};
</script>

<style lang="scss">

</style>
