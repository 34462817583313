<template>
  <v-app class="appbg">
    <v-main>
      <!-- Header Bar-->
      <v-app-bar color="#FFFFFF">
        <img class="logo-header" src="leovida_logo_36.png" alt="">
        <v-spacer></v-spacer>
        <img class="leo-text" src="leovida-text.jpg" alt="">
        <v-spacer></v-spacer>
        <div style="width: 40px"/>
      </v-app-bar>

      <div v-if="msgError !== null" class="alerteErrorContainer">
          <div class="alerteError">
            <v-alert
              text
              prominent
              type="error"
              class="alerteError"
            >
              {{ msgError }}
            </v-alert>
          </div>
      </div>

      <AvanceImmediate v-if="activeComponent === 'AvanceImmediate'"/>
      <NouveauContrat v-else-if="activeComponent === 'NouveauContrat'"/>
      <PrelevIBAN v-else-if="activeComponent === 'PrelevIBAN'"/>

    </v-main>
  </v-app>
</template>

<script>
import AvanceImmediate from './components/AvanceImmediate';
import NouveauContrat from './components/NouveauContrat'
import PrelevIBAN from './components/PrelevIBAN'

const BASE_URL = process.env.NODE_ENV === 'development' ? 
  'http://localhost:8000/api/' :
  'https://hop.leovida.com/api/'

export default {
  name: 'App',

  components: {
    NouveauContrat,
    PrelevIBAN,
    AvanceImmediate,
  },

  data: () => ({
    show: false,
    isOnlyIBAN: undefined,
    msgError: null,
    activeComponent: ''
  }),

  async mounted () {
    let uri = window.location.search.substring(1)
    let params = new URLSearchParams(uri)
    const id = params.get('id')
    const key = params.get('key')
    const type = params.get('type') ?? 'signature'
    if (!id || !key) {
      return this.showMessageError({ message: `Oups..., URL invalide`})
    }

    if (type === 'AvanceImmediate') {
      this.activeComponent = 'AvanceImmediate'
    } else {
      try {
        const res = await fetch(BASE_URL + 'Sign/get/' + id + '/' + key)
        const data = await res.json()
        console.log(data)
        if (res.ok === false) {
          return this.showMessageError(data?.error)
        }
        this.isOnlyIBAN = data.isOnlyIBAN
        this.activeComponent = data.isOnlyIBAN ? 'PrelevIBAN' : 'NouveauContrat'
        this.show = true
      } catch (e) {
        return this.showMessageError(e)
      }
    }

    
  },
  methods: {
    showMessageError (e) {
      this.msgError = e?.message || 'Une erreur est survenue.'
    },
  }
};
</script>

<style lang="scss">
  .appbg {
    background-color: #77A380 !important;
  }
  @media only screen and (max-width: 960px) {
    .appbg {
      background-color: white !important;
    }
  }

  @font-face {
    font-family: "Fair Prosper";
    src: url("./assets/fonts/Fair Prosper.ttf") format("truetype")
  }
  .logo-header {
    padding-right: 16px;
  }
  .leo-text {
    float: right;
    height: 48px;
  }

  h2 {
    color: #77a380;
    margin-bottom: 8px;
  }

  fieldset {
    border: 1px solid rgb(175, 175, 175);
    padding: 10px;
    padding-top: 16px;
    // background-color: rgb(245, 245, 245);
    background-color: rgb(250, 250, 250);
    // border-radius: 12px;
    margin-bottom: 16px;
  }
  legend {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 8px;
    background-color: #77a380;
    color: #fff;
    padding: 3px 6px;
    text-align: left;
  }
  h1 {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 8px;
    color: #77a380;
    padding: 3px 6px;
    text-align: left;
  }

</style>
