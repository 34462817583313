<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    :search-input.sync="search"
    itemText="label"
    itemValue="value"
    autocomplete="do-not-autofill"
    no-data-text="Aucun résultat"
    :return-object="true"
    :filter="() => true"
    

    
  ></v-autocomplete>
  <!--  @change="handleChange" // @input="handleInput" //   itemValue="value"  // :valuecomparator="valuecomparator"  // :filter="filterQuery"-->
</template>

<script>
import { CodeCommuneValues } from '../data/CodeCommune';
import fuzzy from 'fuzzy';

export default {
  data: () => ({
    search: '',
  }),
  props: {
    // label: {
    //   type: String,
    //   default: '',
    // },
  },
  computed: {
    items () {
      let search = (this.search && this.search.trim()) ?? ''
      if (!search) {
        // console.log(CodeCommuneValues.filter(item => item.code.startsWith('59')).length)
        return []
      }
      search = search.toLowerCase().replace(/-/g, ' ')
      const suggestions = fuzzy.filter(search, CodeCommuneValues, {
        extract: (el) => el.searchIn,
      })
      .sort((a, b) => (b.score - a.score) || (a.original.originalLabel.length - b.original.originalLabel.length))
      .map(r => r.original).slice(0, 500)
      return suggestions;
    },
  },
  created () {

  },
  methods: {
    // handleChange (objectData) {
    //   console.log(objectData)
    //   // this.$emit('change', objectData.value, objectData.code, objectData.codePostals)
    // },
    // filterQuery (item, queryText, itemText) {
    //   console.log(itemText)
    //   return false
    //   // return itemText.replace(/\\-/g, ' ').toLocaleLowerCase().indexOf(queryText.replace(/\\-/g, ' ').toLocaleLowerCase()) > -1
    // },
    // valueComparator (a, b) {
    //   console.log({a, b})
    //   return a === b
    // }
  }
}
</script>

<style>

</style>