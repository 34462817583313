<template>
  <v-card class="text-center full-height" elevation="0">

    <div class="d-flex justify-center flex-column align-center" style="padding-top: 64px; padding-bottom: 24px">
      <div>
        <img :src="imgLogo" class="imgLogo" />
      </div>
      <br>
      <section>
        Bienvenu sur LeoSign.<br>
        <br>
        Vous allez être guidé(e) pour signer numeriquement votre contrat avec Leovida.<br>
      </section>
    </div>

    <div class="buttonGroup align-self-end">
      <div class="d-flex justify-end">
        <v-btn color="primary" @click="nextStep">Suivant</v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import imgLogo from '../../assets/imgs/logo.jpg'

export default {
  data: () => ({
    imgLogo
  }),

  methods: {
    nextStep() {
      this.$emit("nextStep")
    }
  }
}
</script>

<style></style>