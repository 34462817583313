<template>
  <v-card class="text-center full-height" elevation="0">
    <v-container class="pa-4 pa-md-4">
      <p class="text-left" v-show="message">
        {{ message }}
      </p>
      <v-form ref="form">
        <fieldset>
          <FormBuilder
            v-model="InputParticulier"
            :schema="InputParticulierForm"
            :yupSchema="YupSchemaInputParticulier"
            @inputItem="onChangeInput"
            :showAllError="showAllError"
          />
        </fieldset>

        <fieldset>
          <legend>Adresse</legend>
          <FormBuilder
            v-model="InputParticulier"
            :schema="InputParticulierForm2"
            :yupSchema="YupSchemaInputParticulier"
            @inputItem="onChangeInput"
            :showAllError="showAllError"
          />
        </fieldset>
        <fieldset>
          <legend>Coordonnée bancaire</legend>
          <FormBuilder
            v-model="InputParticulier"
            :schema="InputParticulierForm3"
            :yupSchema="YupSchemaInputParticulier"
            @inputItem="onChangeInput"
            :showAllError="showAllError"
          />
        </fieldset>
        <fieldset>
          <legend>Contacts</legend>
          <FormBuilder
            v-model="InputParticulier"
            :schema="InputParticulierForm4"
            :yupSchema="YupSchemaInputParticulier"
            @inputItem="onChangeInput"
            :showAllError="showAllError"
          />
        </fieldset>
      </v-form>

    </v-container>
  </v-card>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import set from "lodash/set";
import get from "lodash/get";
import { CodePaysINSEEValues } from "../data/CodePays";
import { YupSchemaInputParticulier } from "../Schema/InputSchema";

import FormBuilder from "./FormBuilder";
import AutocompleteCommune from "../components/AutocompleteCommune.vue";
import { CodeNumeroVoieValues } from "../data/CodeNumeroVoie";
import { CodeVoieValues } from "../data/CodeVoie";

const TesteYup = (yup, key, value, context) => {
  try {
    const val = set({}, key, value);
    yup.validateSyncAt(key, val, { abortEarly: true, context: context?.$data?.InputParticulier});
    return true;
  } catch (e) {
    return e.message;
  }
};
const YupRules = function (context, yup, key) {
  return {
    rules: [(value) => TesteYup(yup, key, value, context)],
  };
};

const InputParticulierForm = (context) => [
  {
    label: "Civilité",
    name: "civilite",
    size: 12,
    type: "select",
    items: [
      { text: "Monsieur", value: "1" },
      { text: "Madame", value: "2" },
    ],
    ...YupRules(context, YupSchemaInputParticulier, "civilite"),
  },
  {
    label: "Prénoms",
    name: "prenoms",
    size: 4,
    ...YupRules(context, YupSchemaInputParticulier, "prenoms"),
  },
  {
    label: "Nom de naissance",
    name: "nomNaissance",
    size: 4,
    ...YupRules(context, YupSchemaInputParticulier, "nomNaissance"),
  },
  {
    label: "Nom d'usage",
    name: "nomUsage",
    size: 4,
    ...YupRules(context, YupSchemaInputParticulier, "nomUsage"),
  },
  {
    label: "Date de naissance",
    name: "dateNaissance",
    type: "date",
    size: 3,
    ...YupRules(context, YupSchemaInputParticulier, "dateNaissance"),
  },
  {
    label: "Pays de naissance",
    name: "lieuNaissance.codePaysNaissance",
    size: 3,
    type: "autocomplete",
    items: CodePaysINSEEValues,
    itemText: "label",
    itemValue: "value",
    ...YupRules(context, YupSchemaInputParticulier, "lieuNaissance.codePaysNaissance"),
  },
  {
    vif: (input) => input?.lieuNaissance?.codePaysNaissance === "99100",
    label: "N° Département de naissance",
    name: "lieuNaissance.departementNaissance",
    size: 3,
    ...YupRules(context, 
      YupSchemaInputParticulier,
      "lieuNaissance.departementNaissance"
    ),
  },
  {
    vif: (input) => input?.lieuNaissance?.codePaysNaissance === "99100",
    label: "Commune de naissance",
    name: "lieuNaissance.communeNaissance.libelleCommune",
    type: "autocompleteCommune",
    size: 3,
    ...YupRules(context, YupSchemaInputParticulier, "lieuNaissance.communeNaissance.libelleCommune"),
  },
];
const InputParticulierForm2 = (context) => [
  {
    label: "Numero de voie",
    name: "adressePostale.numeroVoie",
    size: 2,
    placeholder: "24",

    ...YupRules(context, YupSchemaInputParticulier, "adressePostale.numeroVoie"),
  },
  {
    label: "Lettre",
    name: "adressePostale.lettreVoie",
    size: 2,
    type: "select",
    items: CodeNumeroVoieValues,
    itemText: "label",
    itemValue: "value",
    ...YupRules(context, YupSchemaInputParticulier, "adressePostale.lettreVoie"),
  },

  {
    label: "Type de voie",
    name: "adressePostale.codeTypeVoie",
    size: 2,
    type: "select",
    items: CodeVoieValues,
    itemText: "label",
    itemValue: "value",
    ...YupRules(context, YupSchemaInputParticulier, "adressePostale.codeTypeVoie"),
  },
  {
    label: "Libellé de la voie",
    name: "adressePostale.libelleVoie",
    size: 6,
    ...YupRules(context, YupSchemaInputParticulier, "adressePostale.libelleVoie"),
  },
  {
    label: "Complément",
    name: "adressePostale.complement",
    size: 6,
    ...YupRules(context, YupSchemaInputParticulier, "adressePostale.complement"),
  },
  {
    label: "Lieu dit",
    name: "adressePostale.lieuDit",
    size: 6,
    ...YupRules(context, YupSchemaInputParticulier, "adressePostale.lieuDit"),
  },
  {
    label: "Commune",
    name: "adressePostale.libelleCommune",
    size: 4,
    type: "autocompleteCommune",
    ...YupRules(context, YupSchemaInputParticulier, "adressePostale.libelleCommune"),
  },
  {
    label: "Code postal",
    name: "adressePostale.codePostal",
    size: 4,
    ...YupRules(context, YupSchemaInputParticulier, "adressePostale.codePostal"),
  },
  {
    label: "Pays",
    name: "adressePostale.codePays",
    size: 4,
    type: "autocomplete",
    items: CodePaysINSEEValues,
    itemText: "label",
    itemValue: "value",
    ...YupRules(context, YupSchemaInputParticulier, "adressePostale.codePays"),
  },
];

// ==== CoordonneeBancaire
const InputParticulierForm3 = (context) => [
  {
    label: "IBAN",
    name: "coordonneeBancaire.iban",
    size: 8,
    ...YupRules(context, YupSchemaInputParticulier, "coordonneeBancaire.iban"),
  },
  {
    label: "Bic",
    name: "coordonneeBancaire.bic",
    size: 4,
    ...YupRules(context, YupSchemaInputParticulier, "coordonneeBancaire.bic"),
  },
  {
    label: "Titulaire",
    name: "coordonneeBancaire.titulaire",
    ...YupRules(context, YupSchemaInputParticulier, "coordonneeBancaire.titulaire"),
  },
];

const InputParticulierForm4 = (context) => {
  return [
    {
      label: "Numero de téléphone portable",
      size: 6,
      name: "numeroTelephonePortable",
      ...YupRules(context, YupSchemaInputParticulier, "numeroTelephonePortable"),
    },
    {
      label: "Adresse mail",
      size: 6,
      name: "adresseMail",
      ...YupRules(context, YupSchemaInputParticulier, "adresseMail"),
    },
  ]
}

const DEFAULT_FORM_VALUE = {
  civilite: "",
  nomNaissance: "",
  nomUsage: "",
  prenoms: "",
  dateNaissance: "",
  lieuNaissance: {
    codePaysNaissance: "",
    departementNaissance: "",
    communeNaissance: {
      codeCommune: "",
      nomCommune: "",
    },
  },
  numeroTelephonePortable: "",
  adressePostale: {
    numeroVoie: "",
    lettreVoie: "",
    codeTypeVoie: "",
    libelleVoie: "",
    complement: "",
    lieuDit: "",
    codeCommune: "",
    codePostal: "",
    codePays: '99100',
  },
  adresseMail: "",
  coordonneeBancaire: {
    bic: "",
    iban: "",
    titulaire: "",
  },
}

// console.log('==== DEBUG = USE DEFAULT FORM AvanceImmediateForm')
// const DEFAULT_FORM_VALUE = {
//   civilite: "1",
//   nomNaissance: "DERR",
//   nomUsage: "DERR",
//   prenoms: "DERR",
//   dateNaissance: "",
//   lieuNaissance: {
//     codePaysNaissance: "",
//     departementNaissance: "",
//     communeNaissance: {
//       codeCommune: "",
//       nomCommune: "",
//     },
//   },
//   numeroTelephonePortable: "",
//   adressePostale: {
//     numeroVoie: "267",
//     lettreVoie: "",
//     codeTypeVoie: "",
//     libelleVoie: "Grande",
//     complement: "",
//     lieuDit: "",
//     codeCommune: "59100",
//     codePostal: "59100",
//     codePays: '99100',
//   },
//   adresseMail: "",
//   coordonneeBancaire: {
//     bic: "AAAABBCC",
//     iban: "FR15 1009 6000 7095 3323 8221 H51",
//     titulaire: "",
//   },
// }

export default {
  name: "AvanceImmediate",

  components: {
    FormBuilder,
    AutocompleteCommune,
  },

  data: function () {
    return {
    InputParticulier: DEFAULT_FORM_VALUE,
    InputParticulierForm: InputParticulierForm(this),
    InputParticulierForm2: InputParticulierForm2(this),
    InputParticulierForm3: InputParticulierForm3(this),
    InputParticulierForm4: InputParticulierForm4(this),
    YupSchemaInputParticulier: YupSchemaInputParticulier,
    showAllError: true,
    isCheck: false,
    }
  },

  props: {
    message: {
      type: String,
      required: false,
      default: () => null,
    },
    inputParticulier: {
      type: Object,
      required: false,
    }
  },

  computed: {},

  watch: {
    inputParticulier () {
      this.InputParticulier = {
        ...DEFAULT_FORM_VALUE,
        ...this.inputParticulier,
      }
    }
  },

  mounted() {
    if (this.inputParticulier) {
      this.InputParticulier = {
        ...DEFAULT_FORM_VALUE,
        ...this.inputParticulier,
      }
    }
  },

  methods: {
    get(object, key) {
      const val = get(object, key);
      return val;
    },
    inputChange(event, key) {
      this.touched[key] = true;
    },
    onChangeInput(key, value, ...args) {
      if (this.isCheck === true) {
        this.$refs.form.validate();
      }
      if (args[0] && key === 'lieuNaissance.communeNaissance.libelleCommune') {
        if (this.InputParticulier?.lieuNaissance?.communeNaissance) {
           this.$set(
            this.InputParticulier.lieuNaissance.communeNaissance, 
            'codeCommune',
            args[0]
          )
        }
       
      } else if (args[0] && key === 'adressePostale.libelleCommune') {
        this.$set(this.InputParticulier.adressePostale, 'codeCommune', args[0])

        if (args[1] && args[1].length === 1) {
          this.$set(this.InputParticulier.adressePostale, 'codePostal', args[1][0])
        } else {
          this.$set(this.InputParticulier.adressePostale, 'codePostal', '')
        }
      } 
      // const newValue = set(this.InputParticulier, key, value)
      // console.log(newValue)
      // this.$set(this.InputParticulier, newValue)
    },
    fixOutData (inputParticulier) {
      const outParticulier = JSON.parse(JSON.stringify(inputParticulier))

      if (outParticulier?.lieuNaissance?.communeNaissance?.libelleCommune) {
        outParticulier.lieuNaissance.communeNaissance.libelleCommune =
          outParticulier.lieuNaissance.communeNaissance.libelleCommune.replace(/ \[[0-9, ]+\]/, '')
      }

      if (outParticulier?.adressePostale?.libelleCommune) {
        outParticulier.adressePostale.libelleCommune =
          outParticulier.adressePostale.libelleCommune.replace(/ \[[0-9, ]+\]/, '')
      }

      if (outParticulier?.coordonneeBancaire?.iban) {
        outParticulier.coordonneeBancaire.iban = outParticulier.coordonneeBancaire.iban.replace(/\s/g, '')
      }

      return outParticulier
    },
    getData () {
      const isValid = this.$refs.form.validate();
      this.isCheck = true
      if (isValid) {
        return this.fixOutData(this.InputParticulier)
      } else {
        return null
      }
    }
  },
};
</script>

<style lang="scss">

</style>
