<template>
  <v-container class="pa-0 pa-md-4" >
    <div v-if="msgError !== null" class="alerteErrorContainer">

          <div class="alerteError">
            <v-alert
              text
              prominent
              type="error"
              class="alerteError"
            >
              {{ msgError }}
            </v-alert>
          </div>

    </div>
    <div v-else >
      <v-stepper v-model="step">

        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">Préambule</v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="step > 2" step="2">Mes infos</v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="step > 3" step="3">Mon IBAN</v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="step > 4" step="4">Signature</v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="step > 5" step="5">Fin</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <!-- Préambule -->
          <v-stepper-content step="1" class="stepper text-center">
            <v-card
                v-if="loading"
                class="full-height d-flex justify-center flex-column align-center"
                elevation="0"
                height="100%"
              >
              <v-progress-circular
                :size="140"
                :width="2"
                color="#97BF10"
                indeterminate
              ></v-progress-circular>
            </v-card>
            <template v-else>
              <v-card class="text-center full-height" elevation="0" >
                 
                <div class="d-flex justify-center flex-column align-center" style="padding-top: 64px; padding-bottom: 24px">
                  <div>
                    <img :src="imgLogo" class="imgLogo" />
                  </div>
                  <br>
                  <section>
                    Bienvenu sur LeoSign.<br>
                    <br>
                    Vous allez être guidé pour signer numeriquement votre mandat de prélèvement SEPA<br>
                  </section>
                </div>

                <div class="buttonGroup align-self-end">
                  <div class="d-flex justify-end">
                    <v-btn color="primary" @click="step = 2">Suivant</v-btn>
                  </div>
                </div>
              </v-card>
            </template>
          </v-stepper-content>

          <!-- Mes infos -->
          <v-stepper-content step="2" class="stepper text-center">
            <v-card
                v-if="loading"
                class="full-height d-flex justify-center flex-column align-center"
                elevation="0"
                height="100%"
              >
              <v-progress-circular
                :size="140"
                :width="2"
                color="#97BF10"
                indeterminate
              ></v-progress-circular>
            </v-card>
            <template v-else>
              <v-card class="full-height d-flex justify-center flex-column align-center" elevation="0">
                <v-container fluid>
                  <Field :value="titre" label="Titre" />
                  <Field :value="nom" label="Nom" />
                  <Field :value="prenom" label="Prénom" />
                  <Field :value="email" label="Email" />
                  <Field :value="telephone" label="Téléphone" />
                  <v-spacer />
                  <Field :value="adresse" label="Adresse" />
                  <Field :value="adresseCplt" label="Adresse Cplt" />
                  <Field :value="codePostal + ' ' + ville" label="Code postal - Ville" />
                </v-container>
                <div class="buttonGroup align-self-end">
                  <v-btn color="error" class="ma-1"  plain @click="showError = true">Une erreur ?</v-btn>
                  <div class="d-flex justify-space-between">
                    <v-btn color="primary" text @click="step = 1">Précedent</v-btn>
                    <v-btn color="primary" @click="step = 3">Suivant</v-btn>
                  </div>
                </div>
              </v-card>
            </template>
          </v-stepper-content>

          <!-- Mode de reglemenets-->
          <v-stepper-content step="3" class="stepper">
            <v-card class="full-height d-flex justify-center flex-column align-center" elevation="0">
              <v-container fluid>
                <h2 class="hRIB">Votre RIB</h2>
                <v-text-field
                  v-model="RgleNom"
                  :rules="[v => !!v || '']"
                  label="Nom"
                ></v-text-field>

                <v-text-field
                  :rules="[v => !!v || '']"
                  v-model="RglePrenom"
                  label="Prenom"
                ></v-text-field>
                <v-text-field
                  v-model="RgleIBAN"
                  :rules="[checkIBAN]"
                  label="IBAN"
                  placeholder="FR## #### #### #### #### #### X##"
                ></v-text-field>
              </v-container>
              <div class="buttonGroup align-self-end">
                <div class="d-flex justify-space-between">
                  <v-btn color="primary" text @click="step = 2">Précedent</v-btn>
                  <v-btn :disabled="!validPayement" class="primary" @click="step = 4">Suivant</v-btn>
                </div>
              </div>
            </v-card>
          </v-stepper-content>

          <!-- Signature -->
          <v-stepper-content step="4" class="stepper text-center">
            <v-card
                v-if="loading"
                class="full-height d-flex justify-center flex-column align-center"
                elevation="0"
                height="100%"
              >
              <v-progress-circular
                :size="140"
                :width="2"
                color="#97BF10"
                indeterminate
              ></v-progress-circular>
            </v-card>
            <v-card v-else class="full-height d-flex justify-center flex-column align-center" elevation="0">
              <v-container fluid style="height:100%" class="pdf-view">
                <div>
                  <pdf :src="PDFObjectData" :page="1" :forceReRenderKey="forceReRenderKey"/>
                  <v-spacer style="border-bottom: 1px solid black"/>
                </div>
                <v-spacer style="margin-top:16px"/>
                <div class="buttonGroup align-self-end">
                  <div class="d-flex justify-space-between">
                    <v-btn color="primary" text  @click="step = 3">Précedent</v-btn>
                    <v-btn color="primary" @click="signPDF">Signer</v-btn>
                  </div>
                </div>
                <!-- <object :data="PDFObjectData" type="application/pdf" width="100%" height="100%" ></object> -->
              </v-container>

            </v-card>
          </v-stepper-content>

          <v-stepper-content step="5" class="stepper">
            <v-card
                v-if="loading"
                class="full-height d-flex justify-center flex-column align-center"
                elevation="0"
                height="100%"
              >
              <v-progress-circular
                :size="140"
                :width="2"
                color="#97BF10"
                indeterminate
              ></v-progress-circular>
            </v-card>
            <v-card v-else class="full-height d-flex justify-center flex-column align-center" elevation="0">
              <v-alert
                text
                prominent
                type="success"
                class="alerteError"
              >
                Un exemplaire du mandat vous a été adressé par mail.
              </v-alert>
              <v-container fluid style="height:100%" class="pdf-view">
                <div>
                  <pdf :src="PDFObjectData" :page="1" :forceReRenderKey="forceReRenderKey"/>
                  <v-spacer style="border-bottom: 1px solid black"/>
                </div>
              </v-container>
            </v-card>
          </v-stepper-content>

        </v-stepper-items>
      </v-stepper>
    </div>
    

    <!-- Signature -->
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title class="headline lighten-2">
          Signature
        </v-card-title>
        <v-card-text>
          
          <div class="tabsSign">
            <canvas-sign
              :valid.sync="hasSignature"
              @change="onDataURIChange"
            />
          </div>
          <v-text-field v-model="signataire" :rules="[v => !!v || '']" label="Nom complet" required/>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="gray"
            class="ma-1"
            text
            @click="dialog = false"
          >
            Annuler
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="!signataire || !hasSignature"
            text
            @click="sign()"
          >
            Signer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showError"
      max-width="800"
    >
      <v-card>
        <v-card-title class="headline lighten-2">
          Une erreur ?
        </v-card-title>
        <v-card-text>
          <v-textarea v-model="errorMessage" label="" placeholder="Message"/>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            :disabled="!errorMessage"
            text
            @click="onPressError()"
          >
            Signaler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

   

  </v-container>
</template>

<script>
   /* eslint-disable vue/no-unused-components */
import CanvasSign from './CanvasSign/canvas'
import Field from './Field'
import pdf from 'vue-pdf'
import { mask } from 'vue-the-mask'
import { IbanCheck } from '../Utils'
import imgLogo from '../assets/imgs/logo.jpg'

const BASE_URL = process.env.NODE_ENV === 'development' ? 
  'http://localhost:8000/api/' :
  'https://hop.leovida.com/api/'


export default {
  name: 'MesInfos',

  components: { CanvasSign, pdf, Field },
  directives: { mask },

  data: () => ({
    fields: {
      1: [
        { label: 'Titre', key: 'titre' },
        { label: 'Nom', key: 'nom' },
        { label: 'Prenom', key: 'prenom' },
        { label: 'Titre', key: 'email' },
        { label: 'Titre', key: 'telephone' },
      ]
    },
    imgLogo,

    id: null,
    key: null,
    loading: true,
    msgError: null,
    // Stepper
    step: 1,
    //
    titre: '',
    nom: '', 
    prenom: '',
    telephone: '',
    email: '',
    //
    adresse: '',
    codePostal: '',
    ville: '',
    adresseCplt: '',
    //
    presenceClient: false,
    doucleClefIntervenant: false,
    //
    prestaDuree: 0,
    prestaFrequence: '', // 1, // 1 Semaine - 2 Quinzaine
    prestaTarif: 0,
    //
    modeReglement: 'Prélèvement automatique',
    // Prélèvement automatique, CESU, Virement bancaire
    modeReglementItems: ['Prélèvement automatique', 'CESU', 'Virement bancaire'],
    // IBAM
    RgleNom: '',
    RglePrenom: '',
    RgleIBAN: '',
    PDFObjectData: '',
    //
    dialog: false,
    enableDraw: true,
    text: '',
    dataURI: null,
    signataire: '',
    hasSignature: false,
    //
    showError: false,
    errorMessage: '',
    //
    forceReRenderKey: 0,
    // autoStyleHeight: {
    //   height: 'calc(100vh - 188px)',
    //   backgroundColor: 'red'
    // }
  }),

  computed: {
    validPayement () {
      return this.RgleNom.trim() !== '' && this.RglePrenom.trim() !== '' && this.checkIBAN(this.RgleIBAN) === true
    },
    formatData () {
      return {
        fullName: `${this.titre} ${this.nom} ${this.prenom}`
      }
    },
    prestaDureeFormat () {
      const h = String(Math.floor(this.prestaDuree / 60))
      const m = String(this.prestaDuree % 60)
      return h + 'h' + m.padStart(2, '0')
    },
    prestaTarifFormat () {
      return `${this.prestaTarif.toFixed(2)}€ TTC (TVA 10%)`
    }
  },

  watch: {
    step () {
      if (this.step === 4) {
        this.getPreviewPDF()
      }
    }
  },

  mounted () {
    this.fetchDocSign()
    this.autoHeight()
    window.addEventListener('resize', () => {
      this.autoHeight ()
    });
  },

  methods: {
    autoHeight () {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    async fetchDocSign() {
      let uri = window.location.search.substring(1)
      let params = new URLSearchParams(uri)
      const id = params.get('id')
      const key = params.get('key')
      if (!id || !key) {
        return this.showMessageError({ message: `Oups..., URL invalide`})
      }
      try {
        const res = await fetch(BASE_URL + 'Sign/get/' + id + '/' + key)
        const data = await res.json()
        if (res.ok === false) {
          return this.showMessageError(data?.error)
        }
        this.id = id
        this.key = key
        this.titre = data.titre
        this.nom = data.nom
        this.prenom = data.prenom
        this.telephone = data.telephone
        this.email = data.email
        //
        this.adresse = data.adresse
        this.codePostal = data.codePostal
        this.ville = data.ville
        this.adresseCplt = data.adresseCplt
        //
        this.prestaDuree = data.prestaDuree
        this.prestaFrequence = data.prestaFrequence
        this.prestaTarif = data.prestaTarif
        // console.log(data)
        this.loading = false
      } catch (e) {
        return this.showMessageError(e)
      }
      // this.getPreviewPDF()
      // this.loading = false
    },
    async getPreviewPDF () {
      try {
        this.loading = true
        const res = await fetch(BASE_URL + "Sign/preview/", {
          method: 'POST',
          headers: {
            accept: 'application/json, text/plain, */*',
            'content-type': 'application/json;charset=UTF-8',
          },
          body: JSON.stringify({
            id: this.id,
            key: this.key,
            clef: this.doucleClefIntervenant,
            mode: this.modeReglement,
            presenceClient: this.presenceClient,
            doucleClefIntervenant: this.doucleClefIntervenant,
            IBAN: {
              nom: this.RgleNom,
              prenom: this.RglePrenom,
              IBAN: this.RgleIBAN
            },
          })
        });
        const pdfBlob = await res.blob()
        const arrayBuffer = await pdfBlob.arrayBuffer()
        const buffer = new Uint8Array(arrayBuffer)
        // window.ddd = buffer
        // console.log(buffer)
        this.PDFObjectData = buffer
        this.forceReRenderKey++
        this.loading = false
      } catch (e) {
        this.showMessageError(e)
      }
    },
    async sign () {
      this.dialog = false
      try {
        this.loading = true
        const res = await fetch(BASE_URL + 'Sign/signPrelev/', {
          method: 'POST',
          headers: {
            accept: 'application/json, text/plain, */*',
            'content-type': 'application/json;charset=UTF-8',
          },
          body: JSON.stringify({
            id: this.id,
            key: this.key,
            IBAN: {
              nom: this.RgleNom,
              prenom: this.RglePrenom,
              IBAN: this.RgleIBAN
            },
            signataire: this.signataire,
            signData: this.dataURI
          })
        })
        if (res.ok === false) {
          const data = await res.json()
          return this.showMessageError(data?.error)
        }
        const pdfContent = await res.blob()
        const url = URL.createObjectURL(pdfContent)
        this.PDFObjectData = url
        this.loading = false
        this.step += 1
      } catch (e) {
        this.showMessageError(e)
      }
    },
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    signPDF () {
      this.dialog = true
    },
    onDataURIChange (dataURI) {
      this.dataURI = dataURI
    },
    showMessageError (e) {
      this.msgError = e?.message || 'Une erreur est survenue.'
    },
    checkIBAN () {
      return IbanCheck(this.RgleIBAN)
    },
    async onPressError () {
      try {
        this.loading = true
        await fetch(BASE_URL + 'Sign/error/', {
          method: 'POST',
          headers: {
            accept: 'application/json, text/plain, */*',
            'content-type': 'application/json;charset=UTF-8',
          },
          body: JSON.stringify({
            id: this.id,
            key: this.key,
            message: this.errorMessage
          })
        })
        this.showError = false
      } catch (e) {
        this.showMessageError(e)
      }
    }
  }
}
</script>

<style lang="scss">
  .alerteErrorContainer {
    margin: 16px;
  }
  .alerteError {
    background-color: white;
    text-align: center;
    width: auto;
  }

  .imgLogo {
    max-height: 120px;
    max-width: 100%;
  }

  .pdf-view {
    margin: 0 -24px;
    padding: 0;
  }
  
  // Header         =   64    56
  // Step header    =   72    72
  // Step padding   =   16    00
  // paddinf ine    =   24    08
  //  TOTAL         =  216   128
  .stepper {
    // height: calc(100vh - 168px);
    // height: calc(var(--vh, 1vh) * 100 - 168px);
    overflow-y: auto;
  }
  @media only screen and (max-width: 960px) {
    .v-stepper {
      border-radius: 0 !important;
      box-shadow: none !important;
    }
    .stepper {
      // height: calc(100vh - 128px);
      // height: calc(var(--vh, 1vh) * 100 - 128px);
      padding: 8px 16px !important;
      overflow-y: auto;
      
    }
  }

  .v-stepper__wrapper {
    height: 100%;
  }

  .full-height {
    height: 100%;
    min-height: calc(var(--vh, 1vh) * 100 - 216px);
    display: flex !important;
    flex-direction: column;
    justify-items: flex-start;
    overflow-y: auto;
  }
  @media only screen and (max-width: 960px) {
    .full-height {
      height: 100%;
      min-height: calc(var(--vh, 1vh) * 100 - 144px);
    }
  }

  .buttonGroup {
    width: 100%;
    margin-top: auto;
    justify-self: flex-end;
  }

  .spaninfo {
    display: block;
    color: #8c8c8c;
    text-align: center;
    font-size: 14px;
  }
  .hRIB {
    color: #00796b;
    padding-left: 6px;
    padding-top: 6px;
  }

</style>
