export function IbanCheck (ibanStr) {
  // Si le navigateur ne prend pas en charge BigInt, on ignore la validation
  if (!window.BigInt) {
    return true
  }
  
  if (!ibanStr || typeof ibanStr !== 'string') {
    return false
  }

  let value = ibanStr.replace(/\s/g, '').toUpperCase()
  const _4Char = value.substr(0, 4)
  value = value.substr(4) + _4Char
  let decompiledChar = ''
  for (const c of value) {
    const cc = c.charCodeAt(0)
    if (cc >= 48 && cc <= 57) {
      decompiledChar += String(cc - 48)
    } else if (cc >= 65 && cc <= 90) {
      decompiledChar += String(cc - 65 + 10)
    } else {
      return 'Invalide caractere'
    }
  }
  const intValue = window.BigInt(decompiledChar)
  const key = intValue % window.BigInt(97)
  if (key !== window.BigInt(1)) {
    return 'Clef de controle invalide'
  }
  return true
}