<template>
  <v-select
    v-if="schema.type === 'select'"
    :label="schema.label"
    :rules="schema.rules"
    :items="schema.items"
    :item-text="schema.itemText  || 'text'"
    :item-value="schema.itemValue || 'value'"
    clearable
    :value="value"
    @change="onChangeInput(schema.name, $event)"
  />
  <autocompleteCommune
    v-else-if="schema.type === 'autocompleteCommune'"
    :label="schema.label"
    :value="value"
    :rules="schema.rules"
    @change="onChangeInputCommune(schema.name, $event)"
  />
  <v-autocomplete
    v-else-if="schema.type === 'autocomplete'"
    :rules="schema.rules"
    :label="schema.label"
    :items="schema.items"
    :item-text="schema.itemText  || 'text'"
    :item-value="schema.itemValue || 'value'"
    :value="value"
    @change="onChangeInput(schema.name, $event)"
  ></v-autocomplete>
  <v-menu
    v-else-if="schema.type === 'date'"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    @input="setActivePicker('YEAR')"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="computedDateFormatted"
        :label="schema.label"
        v-bind="attrs"
        v-on="on"
        @blur="onBlurInputDate(schema.name, $event)"
        readonly
        :rules="schema.rules"
      ></v-text-field>
    </template>
    <v-date-picker
      :value="value"
      locale="fr-fr"
      :first-day-of-week="1"
      :active-picker.sync="activePicker"
      :max="new Date(Date.now() - 31536000000 * 18).toISOString().substr(0, 10)"
      min="1900-01-01"
      @change="onChangeInputDate(schema.name, $event)"
    ></v-date-picker>
  </v-menu>
  <v-text-field
    v-else
    :label="schema.label"
    :placeholder="schema.placeholder"
    :rules="schema.rules"
    :value="value"
    @input="onChangeInput(schema.name, $event)"
  />
</template>

<script>
import AutocompleteCommune from '../AutocompleteCommune';

export default {
  name: 'FieldBuilder',

  components: {
    AutocompleteCommune,
  },

  props: {
    schema: {
      type: Object,
      required: true
    },
    value: {
      type: [Object, String, Number, Date],
      required: false
    },
    showAllError: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    activePicker: 'YEAR'
  }),

  computed: {
    computedDateFormatted  () {
      if (this.schema.type === 'date') {
         return this.formatDate(this.value)
      }
      return null
    },
  },

  mounted () {
  },

  methods: {
    onChangeInput (...args) {
      this.emitChange(...args)
    },
    onChangeInputDate (inputName, value) {
      this.emitChange(inputName, value)
    },
    onChangeInputCommune (key, objectValue) {
      this.emitChange(key, objectValue.value, objectValue.code, objectValue.CodePostals)
    },
    setActivePicker (val) {
       this.$nextTick(() => {
        this.activePicker = val
      })
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      // console.log({
      //   year,
      //   month,
      //   day
      // })
      if (!year || !month || !day) {
        return null
      }
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      // if (!date) return null
      
      // const [day, month, year] = date.split('/').map(d => parseInt(d, 10))
      //  if (!year || !month || !day) {
      //   return ""
      // }
      // console.log('parseDate', year, month, day)
      // const mDate = moment([year, month - 1, day])
      // console.log(mDate.format('YYYY-MM-DD'))
      // return mDate.format('YYYY-MM-DD')
    },
    onBlurInputDate (inputName, $event) {
      const inputValue = $event.target.value
      const newValue = this.parseDate(inputValue)
      this.onChangeInput(inputName, newValue)

    },
    emitChange (...args) {
      this.$emit('change', ...args)
      this.$emit('inputItem', ...args)
    }
  }
}
</script>

<style>

</style>