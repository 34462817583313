const BASE_URL = process.env.NODE_ENV === 'development' ? 
  'http://localhost:8000/api/' :
  'https://hop.leovida.com/api/'



export default {
  async previewSignMandat (data) {
    const res = await fetch(BASE_URL + "Sign/previewSignMandat/", {
      method: 'POST',
      headers: {
        accept: 'application/json, text/plain, */*',
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(data)
    });
    return res
  }
}