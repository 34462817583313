<template>
  <v-row dense>
    <v-col
      v-for="(input, i) of schema"
      class="d-flex" 
      :key="i" 
      :lg="input.size || 12"
      :sm="12"
      cols="12"
    >
      <FieldBuilder
        v-if="!input.vif || input.vif(value)"
        :schema="input"
        :value="get(value, input.name)"
        :showAllError="showAllError"
        @change="onChangeInput"
      />
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import set from 'lodash/set'
import get from 'lodash/get'

import AutocompleteCommune from '../AutocompleteCommune.vue';
import FieldBuilder from './FieldBuilder.vue'

export default {
  name: 'FormBuilder',

  components: {
    AutocompleteCommune,
    FieldBuilder
  },

  data: () => ({
    activePicker: 'YEAR',
  }),

  props: {
    value: {
      type: undefined,
    },
    schema: {
      type: Array,
      required: true
    },
    showAllError: {
      type: Boolean,
      default: false
    }
  },

  directives: {},

  computed: {
  },

  watch: {
  },

  mounted () {
  },

  methods: {
    setActivePicker (val) {
      this.$nextTick(() => {
        this.activePicker = val
      })
    },
    get (object, key) {
      const val = get(object, key)
      return val
    },
    onChangeInput (key, value, ...args) {
      const newValue = set(this.value, key, value)
      this.$emit('inputItem', key, value, ...args)
      this.$emit('input', newValue)
    }
  }
}
</script>

<style>
  .v-text-field {
    padding-top: 0px;
    margin-top: 0px;
    padding-left: 4px;
    padding-right: 4px;
  }
</style>
